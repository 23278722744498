import { Injectable } from '@angular/core';
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AppConfigsService } from '../services/app-configs/app-configs.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  previousUrl: string;
  constructor(
    private _auth: AuthService,
    public _configs: AppConfigsService,
    private router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  canActivate() {

    if (window.location.pathname !== '/') {
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.route, window.location.pathname);
    }

    return this._auth.isAuthentication();
  }

  canActivateChild() {
    return this._auth.isAuthentication();
  }

}
