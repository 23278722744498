import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-confirm-favourite',
  templateUrl: './modal-confirm-favourite.component.html',
  styleUrls: ['./modal-confirm-favourite.component.scss']
})
export class ModalConfirmFavouriteComponent implements OnInit {
  onClose: any;

  constructor() { }

  ngOnInit() {
  }

}
