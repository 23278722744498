import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';

@Component({
  selector: 'app-modal-saved-location',
  templateUrl: './modal-saved-location.component.html',
  styleUrls: ['./modal-saved-location.component.scss'],
})
export class ModalSavedLocationComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  addressTypes = this._configs.addressTypes;
  dataLoaded = false;
  dataLoading = false;
  type;
  data;
  reqType;
  savedLocation: any;
  userAddresses = [];
  pincode: any;
  constructor(
    public bsModalRef: BsModalRef,
    private apiHandler: ApiHandlerService,
    public _configs: AppConfigsService,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getListAddress();
    if (this.reqType === 'add' || this.type === 'cart') {
      this.initData();
    } else {
      this.data.address_type =
        this.data.address_type === 'Home'
          ? 'residential'
          : this.data.address_type === 'Office/Commercial'
            ? 'commercial'
            : 'NA';
      this.savedLocation = Object.assign({}, this.data);
    }
  }

  initData() {
    this.savedLocation = {
      // id: '',
      // customer_id: '',
      addr_line1: '',
      addr_line2: '',
      address_type: '',
      city: '',
      state: '',
      zipcode: '',
    };
  }
  getListAddress() {
    this.apiHandler
      .listLocation({}, this._configs.apiKeys.customerAddresses)
      .subscribe(
        (data: any) => {
          this.userAddresses = data.list;
        },
        (err) => {
          this._configs.serverMessageHandler(err);
        }
      );
  }
  submitData(submitForm) {
    submitForm.submitted = true;
    if (submitForm.valid) {
      this.dataLoaded = false;
      this.dataLoading = true;
      const appConfig: any = JSON.parse(
        localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.appConfigs)
      );
      // appConfig.servingArea.indexOf(this.pincode) > -1;
      if (appConfig.servingArea.indexOf(this.pincode) < 0 && this.type === 'cart') {
        // tslint:disable-next-line: max-line-length
        this._configs.success('We have started our services in a limited number of cities. Please continue to submit your order. We will evaluate if we can fulfill your requirements and revert within 36 hours.');
      }

      let queryStr;
      let strMessage = '';
      if (this.reqType === 'add' || this.type === 'cart') {
        queryStr = this.apiHandler.savedLocation(
          this.savedLocation,
          this._configs.apiKeys.customerAddresses
        );
        strMessage = this.translate.instant('LOAD.SUCCESS.SAVED_LOACTION');
      } else {
        queryStr = this.apiHandler.editLocation(
          this.savedLocation,
          this._configs.apiKeys.customerAddresses,
          this.data.id
        );
        strMessage = this.translate.instant('LOAD.SUCCESS.UPDATE_LOACTION');
      }
      queryStr.subscribe(
        (data: any) => {
          if (this.type === 'cart') {
            this.sendAddressId(data.item);
          } else {
            this.event.emit(data);
          }
          // this.getListAddress();
          this._configs.success(strMessage);
          this.dataLoaded = true;
          this.dataLoading = false;
        },
        (err) => {
          this.dataLoaded = true;
          this.dataLoading = false;
          this._configs.serverMessageHandler(err);
        }
      );
    } else {
      setTimeout(() => {
        if (document.querySelector('.invalid-tooltip')) {
          document.querySelector('.invalid-tooltip').scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }

  closeModel(flag) {
    // submitForm.form.markAsPristine();
    // submitForm.form.markAsUntouched();
    // submitForm.form.submitted = false;
    this.event.emit(flag);

    this.bsModalRef.hide();

  }

  sendAddressId(data) {
    const appConfig: any = JSON.parse(
      localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.appConfigs)
    );
    if (appConfig.servingArea.indexOf(data.zipcode) < 0 && this.type === 'cart') {
      // tslint:disable-next-line: max-line-length
      this._configs.success('We have started our services in a limited number of cities. Please continue to submit your order. We will evaluate if we can fulfill your requirements and revert within 36 hours.');
    }
    this.event.emit({ savedLocation: { item: data } });
    this.bsModalRef.hide();
  }
  getCityState(e) {
    const pincode = {
      pincode: e.target.value,
    };
    this.pincode = e.target.value;
    // pincode.pincode =
    this.apiHandler
      .callPincodeQuery(pincode, this._configs.apiKeys.pincode)
      .subscribe(
        (data: any) => {
          if (data && data.item) {
            this.savedLocation.state = data.item.state;
            this.savedLocation.city = data.item.taluka;
          }
          if (!data.item && data.success) {
            this._configs.error('We could not found the provided Pincode in our database.');
            this.savedLocation.zipcode = '';
            this.savedLocation.state = '';
            this.savedLocation.city = '';
          }
        },
        (err) => {
          this._configs.serverMessageHandler(err);
        }
      );
  }
}
