import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalRescheduleMessageComponent } from '../modal-reschedule-message/modal-reschedule-message.component';

@Component({
  selector: 'app-modal-reschedule-cancel',
  templateUrl: './modal-reschedule-cancel.component.html',
  styleUrls: ['./modal-reschedule-cancel.component.scss']
})
export class ModalRescheduleCancelComponent implements OnInit {
  formData: any;
  hoursArray = [];
  minuteArray = [];
  dataLoaded = false;
  dataLoading = false;
  data;
  minDate: Date;
  isMessageShow = false;
  Close: any;
  transHH; transMM;
  constructor(
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private apiHandler: ApiHandlerService,
    public _configs: AppConfigsService,
    private modalService: BsModalService

  ) {
    this.setHoursandMinute(1, 13, this.hoursArray);
    this.minuteArray = [{
      id: '00',
      value: '00'
    }, {
      id: '30',
      value: 30
    }];
    this.transHH = this.translate.instant('LOAD.FORM.HH');
    this.transMM = this.translate.instant('LOAD.FORM.MM');
  }
  ngOnInit() {
    this.initData();
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  initData() {
    this.formData = {
      id: this.data.id,
      shoot_date: this.data.admin_approved_shoot_date
        ? this.setDob(this.data.admin_approved_shoot_date) : this.setDob(this.data.shoot_date),
      shoot_hour: this.data.shoot_hour < 10 ? '0' + this.data.shoot_hour : this.data.shoot_hour,
      shoot_minute: this.data.shoot_minute < 10 ? '0' + this.data.shoot_minute : this.data.shoot_minute,
      shoot_meridiem: this.data.shoot_meridiem,
      type: 'canceled',
      reason: ''
    };
    this.getHoursMinutes();
  }

  submitData(submitForm) {
    let queryMsg = '';
    // if (this.formData.type === 'rescheduled') {
    //   // queryMsg = this.translate.instant('LOAD.SUCCESS.SHOOT_RESCHEDULE');
    // } else {
    //   queryMsg = this.translate.instant('LOAD.SUCCESS.SHOOT_CANCEL');
    // }
    this.setDateTime();
    // this.formData.shoot_date = new Date(this.formData.shoot_date).toISOString();
    this.isMessageShow = true;
    if (submitForm.valid) {
      this.dataLoaded = false;
      this.dataLoading = true;
      this.apiHandler.callOrderRescheduleQuery(this.formData, this._configs.apiKeys.orders, this.data.id).subscribe((data: any) => {
        this.isMessageShow = true;
        this.Close({ formData: this.formData, data: data.item });
        this.bsModalRef.hide();

        if (this.formData.type === 'rescheduled') {
          if ((!data.item || !data.item.isUpdateShootDate)) {
            this.opanModal();
          } else {
            // console.log(this.data.order_number);
            queryMsg = this.translate.instant('LOAD.SUCCESS.SHOOT_RESCHEDULE', { orderNumber: this.data.order_number });
            this._configs.success(queryMsg);

          }
        } else {
          queryMsg = this.translate.instant('LOAD.SUCCESS.SHOOT_CANCEL', { orderNumber: this.data.order_number });
          this._configs.success(queryMsg);

        }

        this.dataLoaded = true;
        this.dataLoading = false;
      }, (err) => {
        this.dataLoaded = true;
        this.dataLoading = false;
        this._configs.serverMessageHandler(err);
      });
    }
  }
  toggleTime() {
    if (this.formData.shoot_meridiem === '') {
      this.formData.shoot_meridiem = 'PM';
    }

    if (this.formData.shoot_meridiem === 'AM') {
      this.formData.shoot_meridiem = 'PM';
    } else {
      this.formData.shoot_meridiem = 'AM';
    }

  }
  setDateTime() {
    this.formData.shoot_date = new Date(this.formData.shoot_date);
    let tempHours = parseInt(this.formData.shoot_hour, 0);
    const tempMinute = parseInt(this.formData.shoot_minute, 0);
    if (this.formData.shoot_meridiem === 'PM' && tempHours < 12) {
      tempHours = tempHours + 12;
    }
    if (this.formData.shoot_meridiem === 'AM' && tempHours === 12) {
      tempHours = tempHours - 12;
    }
    this.formData.shoot_date.setHours(tempHours, tempMinute);
  }

  opanModal() {
    this.bsModalRef = this.modalService.show(ModalRescheduleMessageComponent, {
      class: 'modal-md',
      backdrop: true,
      ignoreBackdropClick: false
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  setHoursandMinute(min, max, arrayName) {
    for (let i = min; i < max; i++) {
      let num;
      if (i < 10) {
        num = '0' + i;
      } else {
        num = i;
      }

      arrayName.push({
        id: num,
        value: num
      });
    }
  }
  changeType(event) {
    this.formData.reason = '';
  }
  setDob(date) {
    if (date) {
      date = new Date(date).toISOString();
      const tempDate = new Date(date);
      tempDate.setHours(23, 59, 59);
      date = tempDate;
    }
    return date;
  }
  getHoursMinutes() {
    if (!this.data.admin_approved_shoot_date) {

      if (this.data.shoot_hour < 10) {
        this.formData.shoot_hour = '0' + this.data.shoot_hour;
      } else {
        this.formData.shoot_hour = this.data.shoot_hour;
      }
      if (this.data.shoot_minute < 10) {
        this.formData.shoot_minute = '0' + this.data.shoot_minute;
      } else {
        this.formData.shoot_minute = this.data.shoot_minute;

      }
      this.formData.shoot_meridiem = this.data.shoot_meridiem;
      // console.log('Hours:', this.formData.hours, 'Minutes:', this.formData.shoot_minute);
    } else {
      const currentDate = new Date(this.data.admin_approved_shoot_date);
      this.formData.shoot_hour = currentDate.getHours();
      if (this.formData.shoot_hour > 12) {
        this.formData.shoot_hour = this.formData.shoot_hour - 12;
        this.formData.shoot_meridiem = 'PM';
      }

      if (this.formData.shoot_hour === 0) {
        this.formData.shoot_hour = 12;
        this.formData.shoot_meridiem = 'AM';
      } else if (this.formData.shoot_hour === 12) {
        this.formData.shoot_hour = 12;
        this.formData.shoot_meridiem = 'PM';
      }

      // if (this.formData.shoot_hour < 12) {
      //   // this.formData.shoot_hour = this.formData.shoot_hour - 12;
      //   this.formData.shoot_meridiem = 'AM';
      // }

      if (this.formData.shoot_hour < 10) {
        this.formData.shoot_hour = '0' + this.formData.shoot_hour;
      }

      this.formData.shoot_minute = currentDate.getMinutes();
      if (this.formData.shoot_minute < 10) {
        this.formData.shoot_minute = '0' + this.formData.shoot_minute;

      }
      // this.time.type = this.data.shoot_meridiem;
      // console.log(this.data.admin_approved_shoot_date);
      // console.log('Hours:', this.time.hours, 'Minutes:', this.time.shoot_minute);

    }
  }
}
