import { AfterContentInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @ViewChild('inputFile', { static: false }) inputFile: any;
  dataLoaded = false;
  dataLoading = false;
  formData: any = {};
  isUploading = false;
  imageUplaod;
  userDetails: any = {};
  public routerConfigs = [];
  activeRoute;
  percentDone: number;
  image: any;
  constructor(
    public _configs: AppConfigsService,
    public translate: TranslateService,
    private apiHandler: ApiHandlerService

  ) {

    this._configs.currentActiveRoute.subscribe(el => {
      this.activeRoute = el;
      // console.log(this.activeRoute);
      // console.log(this.router.url);
    });

  }

  ngOnInit() {
    this.initData();
    // this.getUser();
    this.getProfile();

    this._configs.shareGetPrifile.subscribe((data) => {
      // console.log(data);
      this.getUser(data);
    });
  }

  initData() {
    this.routerConfigs = [
      {
        url: this._configs.routeUrl.dashboard,
        label: 'LOAD.PAGE_NAME.DASHBOARD',
        class: 'fa fa-dashboard',
        routeName: 'profile',
        linkUrl: this._configs.routeUrl.dashboard
      },
      {
        url: this._configs.routeUrl.profile,
        label: 'LOAD.PAGE_NAME.PROFILE',
        class: 'fa fa-user-circle-o',
        routeName: 'profile',
        linkUrl: this._configs.routeUrl.profile
      },
      {
        url: this._configs.routeUrl.sadhana,
        label: 'LOAD.PAGE_NAME.SADHANA',
        class: 'arhamvijja-bag',
        routeName: 'sadhana',
        linkUrl: this._configs.routeUrl.sadhana
      },
      {
        url: this._configs.routeUrl.transactions,
        label: 'LOAD.PAGE_NAME.TRANSACTIONS',
        class: 'fa fa-exchange',
        routeName: 'transactions',
        linkUrl: this._configs.routeUrl.transactions
      },
      {
        url: this._configs.routeUrl.supportTickets,
        label: 'LOAD.PAGE_NAME.SUPPORT_TICKETS',
        class: 'fa fa-ticket',
        routeName: 'support-tickets',
        linkUrl: this._configs.routeUrl.supportTickets
      },
      {
        url: this._configs.routeUrl.referAFriend,
        label: 'LOAD.PAGE_NAME.REFER_A_FRIEND',
        class: 'arhamvijja-user',
        routeName: 'refer-a-friend',
        linkUrl: this._configs.routeUrl.referAFriend
      },
      {
        url: this._configs.routeUrl.becomeVolunteer,
        label: 'LOAD.PAGE_NAME.BECOME_VOLUNTEER',
        class: 'fa fa-support',
        routeName: 'becomeVolunteer',
        linkUrl: this._configs.routeUrl.becomeVolunteer
      },
      // {
      //   url: this._configs.routeUrl.quotations,
      //   label: 'LOAD.PAGE_NAME.QUOTATIONS',
      //   class: 'arhamvijja-edit',
      //   routeName: 'quotations',
      //   linkUrl: this._configs.routeUrl.quotations
      // },
      // {
      //   url: this._configs.routeUrl.orders,
      //   label: 'LOAD.PAGE_NAME.MY_COURSES',
      //   class: 'arhamvijja-bag',
      //   routeName: 'orders',
      //   linkUrl: this._configs.routeUrl.orders
      // },
      // {
      //   url: this._configs.routeUrl.favourites,
      //   label: 'LOAD.PAGE_NAME.FAVOURITES',
      //   class: 'fa fa-heart',
      //   routeName: 'favourites',
      //   linkUrl: this._configs.routeUrl.favourites
      // }
    ];
  }

  onPhotoChange(event) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(event.target.files[0]);
    reader.addEventListener('loadend', (e) => {

      this.apiHandler.fetchUploadUrl({
        ext: event.target.files[0].name.split('.').reverse()[0],
        type: event.target.files[0].type
      }).subscribe((data: any) => {
        if (data) {
          this.apiHandler.uploadImages(data.uploadUrl, new Blob([reader.result], {
            type: event.target.files[0].type
          }), event.target.files[0].type).subscribe(() => {
            this.formData.photo_default = data.bucketUrl + data.fileName;
            this.formData.photo_medium = data.bucketUrl + data.fileName;
            this.formData.photo_small = data.bucketUrl + data.fileName;
            this.apiHandler.imageUplod(this.formData, this._configs.apiKeys.change_Photo).subscribe(res => {
              if (res.type === HttpEventType.UploadProgress) {
                this.percentDone = Math.round(100 * res.loaded / res.total);
              } else if (res instanceof HttpResponse) {
                this.getProfile();
                this._configs.success(this.translate.instant('LOAD.SUCCESS.PHOTO_UPLOAD'));
              }
            });

          }, (err) => {
            this._configs.serverMessageHandler(err);
          });
        } else {
          this._configs.error(this.translate.instant('LOAD.ERROR.UPLOAD_ISSUE'));
        }
      });
    });
  }

  getUser(data) {
    // console.log(data);
    this.userDetails.first_name = data.first_name;
    this.userDetails.email = data.email;
    this.userDetails.last_name = data.last_name;
    this.userDetails.phone = data.phone;
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.currentUser, JSON.stringify(this.userDetails));
    // const user = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.currentUser));
    // this.userDetails = user;
  }

  getProfile() {
    this.dataLoading = true;
    this.dataLoaded = false;
    this.apiHandler.getProfile().subscribe((data: any) => {
      this.dataLoaded = true;
      this.dataLoading = false;
      this.getUser(data.item);
      this.image = data.item.photo_default;
    }, (err) => {
      this.dataLoaded = true;
      this.dataLoading = false;
      // Setup localization server massage
      this._configs.serverMessageHandler(err);
    });
  }
}
