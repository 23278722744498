import { Injectable } from '@angular/core';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_URLS;
  API_URLS1;

  constructor(
    public _configs: AppConfigsService,
    private http: HttpClient,
    public router: Router,
    // private socialAuthService: SocialAuthService
  ) {

    this.API_URLS = this._configs.env.apiUrl;
    this.API_URLS1 = this._configs.env.uploadsUrl;

  }

  getToken() {
    return localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
  }

  isAuthentication() {
    const accessToken = this.getToken();
    if (!!accessToken) {
      return true;
    }
    this.router.navigate([this._configs.routeUrl.index]);
    return false;
  }

  doSignIn(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/signin', params);
  }

  isLoggedin() {
    const token = this.getToken();
    if (!!token) {
      this.router.navigate([this._configs.routeUrl.dashboard]);
    }
  }

  signup(params , type) {
    return this.http.post(this.API_URLS + '/auth/' + type, params);
  }

  socialSignin(params, type) {
    return this.http.post(this.API_URLS + '/auth/' + type, params);
  }

  callOTPRequest(params) {
    return this.http.post(this.API_URLS + '/otpRequest', params);
  }

  resedLink(params) {
    return this.http.post(this.API_URLS + '/auth/resend-link', params);
  }

  // callResetPassword(params) {
  //   return this.http.post(this.API_URLS + '/resetPassword', params);
  // }

  forgotPassword(params, type) {
    return this.http.post(this.API_URLS + '/auth/' + type, params);
  }

  lastInteractionSession() {
    const lastInterection = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction)) || 0;
    if ((Date.now() - lastInterection) > this._configs.idleTime) {
      return false;
    }
    this.setLastInterationSession();
  }

  setLastInterationSession() {
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction, JSON.stringify(Date.now()));
  }

  sendOtp(params, type) {
    return this.http.post(this.API_URLS + '/auth/' + type, params);
  }

  signOut(params , type) {
    return this.http.post(this.API_URLS + '/auth/' + type , params);
  }
}
