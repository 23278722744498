import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cart-submit-slot',
  templateUrl: './cart-submit-slot.component.html',
  styleUrls: ['./cart-submit-slot.component.scss']
})

export class CartSubmitSlotComponent implements OnInit {
  onClose: any;
  data: any = {
    title: 'this'
  };
  name;
  confirmMessage: any;
  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef,

  ) {
  }

  ngOnInit() {
    this.confirmMessage = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(this.confirmMessage, {
      name: this.data.title
    }));
  }

}
