import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberonlyDirective } from './numberonly/numberonly.directive';
import { EndDateValidationDirective } from './endDateValidation/end-date-validation.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { WhiteSpaceIgnoreDirective } from './white-space-ignore/white-space-ignore.directive';
import { OnlyAlphabetsDirective } from './onlyAlphabets/only-alphabets.directive';
import { MatchPasswordDirective } from './match-password/match-password.directive';

@NgModule({
  declarations: [NumberonlyDirective, EndDateValidationDirective, ClickOutsideDirective, WhiteSpaceIgnoreDirective, OnlyAlphabetsDirective, MatchPasswordDirective],
  imports: [
    CommonModule
  ],
  exports: [NumberonlyDirective, EndDateValidationDirective, ClickOutsideDirective, WhiteSpaceIgnoreDirective, OnlyAlphabetsDirective, MatchPasswordDirective]
})
export class SharedDirectivesModule { }
