import { Component, OnInit, ɵConsole } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-submitcart',
  templateUrl: './modal-submitcart.component.html',
  styleUrls: ['./modal-submitcart.component.scss']
})
export class ModalSubmitcartComponent implements OnInit {
  onClose: any;
  confirmMessage: any;
  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    this.confirmMessage = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(this.confirmMessage));
  }
}
