import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { AddCartRestrictComponent } from '../add-cart-restrict/add-cart-restrict.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Lightbox } from 'ngx-lightbox';
import { ModalSelectActionComponent } from '../modal-select-action/modal-select-action.component';

@Component({
  selector: 'app-e-commerce-carousel',
  templateUrl: './e-commerce-carousel.component.html',
  styleUrls: ['./e-commerce-carousel.component.scss']
})
export class ECommerceCarouselComponent implements OnInit {
  @Input() images;
  bsModalRefModal: BsModalRef;
  bsModalRef: BsModalRef;
  favouriteList = [];
  private album = [];
  cartAddRemove = false;
  // bsModalRef: BsModalRef;
  isAddCartShow = false;
  favouriteObj: any = [];
  allowToPushCart = 0;
  counter = 0;
  dataLoaded = false;
  dataLoading = false;
  items = [];
  eCommerceImages = [];
  relatedImages: any = [];
  customOptions: OwlOptions = {

    loop: true,
    // autoHeight: true,
    nav: true,
    dots: false,
    // navSpeed: 700,
    // center: true,
    margin: 25,
    URLhashListener: true,
    // startPosition: 'URLHash',
    navText: ['<i class="arhamvijja-arrow-left"></i>', '<i class="arhamvijja-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 1
      }
    },
  };
  constructor(
    private apiHandler: ApiHandlerService,
    public translate: TranslateService,
    public _configs: AppConfigsService,
    private modalService: BsModalService,
    private router: Router,
    private _lightbox: Lightbox
  ) {
    // this.checkLocalStorage('relatedImage');
  }

  ngOnInit() {
    if (this.images.item && this.images.item.carousel_wallpaper) {
      this.eCommerceImages = JSON.parse(this.images.item.images);
      if (this.eCommerceImages && this.eCommerceImages.length > 0) {
        this.eCommerceImages.splice(0, 0, this.images.item.carousel_wallpaper);
      }
    } else if (this.images.item && this.images.item.images) {
      this.eCommerceImages = JSON.parse(this.images.item.images);
    }
    this.setLightBox();

    let cartItemIds = [];
    this._configs.currentCartSelectedItem.subscribe((element: any) => {
      if (element && element.length > 0) {
        cartItemIds = element.map(a => a.sub_category_id);
      }
    });
    if (cartItemIds.length > 0) {
      this.getRelatedItems(cartItemIds);
    }

    // this._configs.currentCartItem.subscribe((data: any) => {
    //   this.checkAlreadydAddedCart();
    // });

    // this._configs.checkIsPackageItem();

  }

  checkLocalStorage(type) {
    let itemIds;
    const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    // this._configs.changeCartLength();
    if (result === null) {
      this.items = [];
    } else {
      this.items = result;
      if (type === 'relatedImage') {
        itemIds = this.items.map(a => a.sub_category_id);
        this.getRelatedItems(itemIds);
      }

    }
  }

  getRelatedItems(itemIds) {
    this.dataLoaded = false;
    this.dataLoading = true;
    this.apiHandler.getRelatedImages({ ids: itemIds }, this._configs.apiKeys.sub_categories).subscribe((data: any) => {
      this.relatedImages = data.list;
      // this.setLightBox();
      // this.checkAlreadydAddedCart();
      // const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
      // if (accessToken) {
      //   this.getFavourites();
      // }
      this.dataLoaded = true;
      this.dataLoading = false;
    }, (err) => {
      this.dataLoaded = true;
      this.dataLoading = false;
      // Setup localization server massage
      this._configs.serverMessageHandler(err);
    });
  }
  setLightBox() {
    this.eCommerceImages.forEach((data) => {
      if (data !== '') {
        const src = data;
        const albumObj = {
          src: src
        };
        this.album.push(albumObj);
      }

    });
  }
  openImage(index: number): void {
    this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true });
  }
}
