import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { AuthService } from '../../services/auth/auth.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-order-summary',
  templateUrl: './modal-order-summary.component.html',
  styleUrls: ['./modal-order-summary.component.scss'],
  providers: [DatePipe, CurrencyPipe]

})
export class ModalOrderSummary implements OnInit {
  onClose: any;
  orderItemData: any;
  dataLoaded = false;
  dataLoading = false;
  objList = [];
  modalRef2: BsModalRef;

  orderEventType = {
    new: 'New Order',
    quoted: 'Quoted Order',
    'approve-rescheduled': 'Approved Shoot Date',
    canceled: 'Cancelled Order',
    rescheduled: 'Reschedule Request',
    'manual-payment': 'Manual Payment',
    'partial-payment': 'Partial Payment',
    'full-payment': 'Full Payment',
    'image-view': 'Image Ready To View',
    'image-download': 'Images Ready To Download',
    'artist-assigned': 'Guru Assigned',
    'customer-canceled': 'Castomer Cancelled',
    're-quote': 'Re-quotation Order'
  };

  html;

  constructor(
    public translate: TranslateService,
    public bsModalRef: BsModalRef,
    public _configs: AppConfigsService,
    public apiHandler: ApiHandlerService,
    public auth: AuthService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private cp: CurrencyPipe,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    // console.log(this.orderItemData);
    this.getOrderSummery();
  }

  getOrderSummery() {
    this.dataLoaded = false;
    this.dataLoading = true;
    this.apiHandler.calOrderSummery({ sortby: 'created_at', sort: 'asc' },
      this._configs.apiKeys.orders, this.orderItemData.id).subscribe((data: any) => {
        if (data.list && data.list.length > 0) {
          // this.orderItemData = data.item;
          this.objList = data.list;
          this.objList.forEach((ele) => {
            if (ele.raw_data) {

              ele.raw_data = JSON.parse(ele.raw_data);

              if (ele.raw_data.estimated_sub_total) {
                ele.raw_data.estimated_sub_total = this.converterInt(ele.raw_data.estimated_sub_total);
              }
              if (ele.raw_data.studio_charges) {
                ele.raw_data.studio_charges = this.converterInt(ele.raw_data.studio_charges);
              }
              if (ele.raw_data.models_charges) {
                ele.raw_data.models_charges = this.converterInt(ele.raw_data.models_charges);
              }
              if (ele.raw_data.stylist_charges) {
                ele.raw_data.stylist_charges = this.converterInt(ele.raw_data.stylist_charges);
              }
              if (ele.raw_data.other_charges) {
                ele.raw_data.other_charges = this.converterInt(ele.raw_data.other_charges);
              }
              if (ele.raw_data.discount) {
                ele.raw_data.discount = this.converterInt(ele.raw_data.discount);
              }

            }

            if (ele.type === 'artist-assigned') {
              this.getArtistData();
            }

          });
        }

        // console.log(this.objList);
        // this.callInit();

        this.dataLoaded = true;
        this.dataLoading = false;
      }, (err) => {
        this.dataLoaded = true;
        this.dataLoading = false;
        this._configs.serverMessageHandler(err);
      });
  }

  converterInt(Value) {
    return Number(Value);
  }

  close() {
    this.onClose(false);
  }

  openModel(template, note) {
    if (note.type === 'quoted' || note.type === 're-quote') {
      if (note.raw_data) {
        note.raw_data.total_cost = (note.raw_data.estimated_sub_total + note.raw_data.studio_charges +
          note.raw_data.models_charges + note.raw_data.stylist_charges + note.raw_data.other_charges) - (note.raw_data.discount);
      }
      // console.log(note.raw_data.total_cost);
      // this.html = this.sanitizer.bypassSecurityTrustHtml(note.raw_data.admin_note);
      if (note.raw_data.admin_note) {
        this.html = note.raw_data.admin_note.replace('{estimated_sub_total}', this.cp.transform(note.raw_data.estimated_sub_total, 'INR'))
          .replace('{studio_charges}', this.cp.transform(note.raw_data.studio_charges, 'INR') || '0')
          .replace('{models_charges}', this.cp.transform(note.raw_data.models_charges, 'INR') || '0')
          .replace('{stylist_charges}', this.cp.transform(note.raw_data.stylist_charges, 'INR') || '0')
          .replace('{other_charges}', this.cp.transform(note.raw_data.other_charges, 'INR') || '0')
          .replace('{discount}', this.cp.transform(note.raw_data.discount, 'INR') || '0')
          .replace('{total_cost}', this.cp.transform(note.raw_data.total_cost, 'INR') || '0')
          .replace('{taxes}', note.raw_data.taxes)
          .replace('{inclusive_taxes}', this.cp.transform(note.raw_data.quoted_price, 'INR'))
          .replace('{admin_approved_status}', (
            this.datePipe.transform(note.raw_data.admin_approved_shoot_date, 'dd/MM/yyyy h:mm a')))
          .replace('{link}',
            `<a class='text-primary' target="_blank"
         href="${note.raw_data.razorpay_url}">${note.raw_data.razorpay_url}</a>`) || 'No Data Found';
      }
    }

    // admin approve request
    if (note.type === 'approve-rescheduled') {
      if (note.raw_data.txt_reschedule) {
        this.html = note.raw_data.txt_reschedule || 'No Data Found';
      }
    }

    // admin cancelled
    if (note.type === 'canceled') {
      if (note.raw_data.txt_cancelled) {
        this.html = note.raw_data.txt_cancelled || 'No Data Found';
      }
    }

    // customer cancelled
    if (note.type === 'customer-canceled') {
      if (note.raw_data.canceledData.txt_cancelled) {
        this.html = note.raw_data.canceledData.txt_cancelled || 'No Data Found';
      }
    }

    // customer reschedule request
    if (note.type === 'rescheduled') {
      if (note.raw_data.reschedule_data.txt_reschedule) {
        this.html = note.raw_data.reschedule_data.txt_reschedule || 'No Data Found';
      }
    }

    if (note.type === 'artist-assigned') {
      if (note.raw_data.artistData) {
        this.html = note.raw_data.artistData || 'No Data Found';
      }
    }

    if (note.type === 'partial-payment' || note.type === 'full-payment' || note.type === 'manual-payment') {
      if (note.raw_data.paymentData.txt_payment) {
        this.html = note.raw_data.paymentData.txt_payment || 'No Data Found';
      }
    }

    if (note.type === 'image-view' || note.type === 'image-download') {
      if (note.raw_data.imageData) {
        this.html = note.raw_data.imageData || 'No Data Found';
      }
    }

    this.modalRef2 = this.modalService.show(template, {
      class: 'modal-xl',
      backdrop: true,
      ignoreBackdropClick: false
    });

  }

  getArtistData() {
    this.objList.forEach((ele) => {
      if (ele.raw_data.artists && ele.raw_data.artists.length > 0) {
        const tempArtistArray = [];
        ele.raw_data.artists.forEach((el) => {
          let firstName;
          let lastName;
          let fullName;
          if (el.artist.first_name && el.artist.first_name !== null && el.artist.first_name !== '') {
            firstName = el.artist.first_name;
          }
          if (el.artist.last_name && el.artist.last_name !== null && el.artist.last_name !== '') {
            lastName = el.artist.last_name;
          }

          if (lastName && lastName !== null && lastName !== '') {
            fullName = firstName + ' ' + lastName;
          } else {
            fullName = firstName;
          }

          tempArtistArray.push(fullName);
          ele.artistNames = tempArtistArray.join(', ');
        });
      }
    });

  }

}

