import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-cart-restrict',
  templateUrl: './add-cart-restrict.component.html',
  styleUrls: ['./add-cart-restrict.component.scss']
})
export class AddCartRestrictComponent implements OnInit {
  closeBtnName: string;
  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

}
