import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as envJS from 'src\\env.js';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
function _window(): any {
  // return the global native browser window object
  return window;
}

const env = envJS.__env;

@Injectable({
  providedIn: 'root'
})

export class AppConfigsService {

  public slotFlag;

  public tempCategory = null;

  public cookiePreFix = 'arham-vijja-member-';

  public lsPrefix = 'arham-vijja-member-';

  public defaultImagePageLoad = './assets/img/placeholder.png';

  public defaultProfilePicture = './assets/img/avatar.png';

  get nativeWindow(): any {
    return _window();
  }

  public title = 'Arham Vijja';
  public env: any;
  public idleTime = (15 * 60 * 1000); // after idle time in milliseconds force loggedout.
  public photoExtensions = '["jpg", "png", "jpeg"]';
  public docExtensions = '["xls", "xlsx", "csv", "pdf"]';
  public textFiles = ["txt"];
  public pdfExtensions = ["pdf"];
  public defaultDateFormat = 'dd/MM/yyyy';
  public dateTimeFormat = 'dd/MM/yyyy, h:mm a';
  public items = [];
  public cartLength;
  public itemsPerPage = 10;
  public orderNum;
  counter = 0;
  public extensions = {
    doc: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf',
    image: 'image/*'
  };

  public patterns = {
    // begginNospace: RegExp(/^[^\s]+[-a-zA-Z0-9?!$%*^()_@./#&+-;',.~`\s]*$/),
    decimalNumber: RegExp(/[0-9]+(\.[0-9][0-9]?)?/),
    email: RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/),
    mobile: RegExp(/([7-9][0-9]{9})$/),
    mobNumberPattern: RegExp(/^((\\+91-?)|0)?[0-9]{10}$/),
    url: RegExp(/^(http|https?:\/\/)?([a-z\.]{2,6})([\/\w \.-]*)*\/?$/),
    onlyNumbers: RegExp(/^\d+$/),
    alphaNumeric: RegExp(/^[a-zA-Z0-9]*$/),
    alphaNumericUnderscore: RegExp(/^[^\s]+[a-zA-Z0-9_\s]+$/),
    alphaNumericUnderscoreDesh: RegExp(/^[^\s][a-zA-Z0-9_.-\s]+$/),
    regNumberOnly: RegExp(/^[0-9]*$/),
    nameNospace: RegExp(/^[^\s]+[-a-zA-Z0-9?!$%*^()_@./#&+-;',.~`\s]*$/),
    ipaddress: RegExp(/^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})$/),
    latitude: RegExp(/^[-+]?([1-8]?\d(\.\d{0,10})?|90(\.0+)?)$/),
    longitude: RegExp(/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d{0,11})?)$/),
    notAllowedZero: RegExp(/^[1-9][0-9]*$/),
    password: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@._#$])[A-Za-z\d@._#$]{8,}$/),
    macId: RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/),
    regPort: RegExp(/^()([0-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/),
    version: RegExp(/^\d+(\.\d+){0,2}$/),
    regURL: RegExp(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/),
    // tslint:disable-next-line:max-line-length
    regURLIP: RegExp(/^(ht|f)tp(s?)\:\/\/(([a-zA-Z0-9\-\._]+(\.[a-zA-Z0-9\-\._]+)+)|localhost)(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?([\d\w\.\/\%\+\-\=\&amp;\?\:\\\&quot;\'\,\|\~\;]*)$/),
    // cpuStorage: RegExp(/(\d+)\s+GB/)
    cpuStorage: RegExp(/^(\d+)(?=(?:\/\d+)*\s*([G]B)$)/),
    host: RegExp(/^[a-zA-Z0-9-_.]*$/),
    email1: RegExp(/^[a-zA-Z0-9._%\+\-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
    tempEmail: RegExp(/[a-zA-Z0-9._%\+\-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3}/),
    tempEmailOrPhone: RegExp(/([7-9][0-9]{9})|([a-zA-Z0-9._%\+\-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,3})/),
    emailOrPhone: '^([7-9][0-9]{9})|([A-Za-z0-9._%\+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,3})',
    // regInterval: RegExp(/^()([0-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/),
    phoneNumberPattern: RegExp('^([7-9][0-9]{9})$'),
    emailPattern: RegExp('^([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$'),
    digitOnlyPattern: RegExp('^[0-9]+$'),
    HH: '0[1-9]|1[0-2]',
    MM: '[0-5][0-9]',
    Num1To5: '^([1-5])$',
    gstin: RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/),
    zero: RegExp(/^[1-9][0-9]*$/)
  };

  public uploadPath = {
    accountProfile: '/account/profile/upload',
    commonImage: '/common/upload-image'
  };

  public labels = {
    success: 'Success!',
    oops: 'Alert!',
    admin: 'Admin',
    super: 'Super',
    card: 'Card',
    netbanking: 'Net Banking',
    upi: 'UPI',
    cheque: 'Cheque',
    cash: 'Cash',
    neft: 'NEFT/RTGS',
    wallet: 'Wallet',
    emi: 'EMI'
  };

  public routeUrl = {
    faqs: '/faqs',
    profile: '/profile',
    quotations: '/quotations',
    orders: '/orders',
    index: '/',
    auth: '/auth',
    about: '/about',
    contact: '/contact',
    terms_and_condition: '/terms-and-condition',
    privacy_policy: '/privacy-policy',
    favourites: '/favorites',
    signin: '/signin',
    signup: '/signup',
    my_cart: '/my-cart',
    congratulations: '/congratulations',
    search: '/category/search',
    dashboard: '/dashboard',
    category: '/category',
    fav: '/my-favorites',
    sadhana: "/sadhana",
    transactions: "/transactions",
    supportTickets: "/support-tickets",
    referAFriend: "/refer-a-friend",
    opportunitiesVolunteer: '/volunteering-opportunities',
    root: "/index",
    becomeVolunteer: "/volunteering-opportunities",
    viewComments: "/view-comments"
  };

  public useImageObj = {
    online_sharing: 'Online sharing',
    e_commerce_digital_marketing: 'eCommerce / digital marketing',
    catalog_album_printing: 'Catalog / album printing',
    bill_boards_flex: 'Bill boards / flex',
    packaging_material: 'Packaging material',
    other_field: 'Others (Please specify)'
  };

  public categories = [
    { id: 'Architecture & Interiors', name: 'Architecture & Interiors' },
    { id: 'Fashion', name: 'Fashion' },
    { id: 'Product', name: 'Product' },
    { id: 'Food', name: 'Food' },
    { id: 'Jewelry', name: 'Jewelry' },
    { id: 'Editorial', name: 'Editorial' },
    { id: 'Industrial', name: 'Industrial' },
    { id: 'Corporate', name: 'Corporate' },
    { id: 'Personal Event', name: 'Personal Event' }
  ];

  public localStorage = {
    appConfigs: 'appConfigs',
    favouriteItems: 'favouriteObj',
    favouriteItemArray: 'favouriteItemArray',
    accessToken: 'x-access-token',
    xTimeZone: 'x-time-zone',
    currentUser: 'current-user',
    permissions: 'permissions',
    lastInteraction: 'last-interaction',
    rydotCookie: 'rydotCookie',
    isPlayTTS: 'sound',
    cartItems: 'cartItems',
    items: 'Items',
    cartId: 'cartId',
    shareItem: 'shareItem',
    route: 'route',
    cartItemId: 'cartID',
    customer_id: 'customer'
  };

  public apiKeys = {
    faqs: 'faqs',
    notifications: 'notifications',
    payments: 'payments/payment-link',
    pending: 'pending',
    completed: 'completed',
    account: 'account',
    change_Photo: 'change-photo',
    customerAddresses: 'customer-addresses',
    pincode: 'city-by-pincode',
    my_cart: 'my-cart',
    profile: 'profile',
    sub_categories: 'sub-categories',
    orders: 'orders',
    auth: 'auth',
    forgot: 'forgot',
    reset_password: 'reset-password',
    social_login: 'social-signin',
    send_otp: 'send-otp',
    signout: 'signout',
    signup: 'signup',
    favourites: 'favourites',
    all: 'all',
    cart_item: 'cart-item',
    search: 'search',
    items: 'items',
    shivirs: 'shivirs',
    public: 'public',
    opportunities: 'opportunities',
    volunteer: 'volunteer',
    referAfriend: 'refer-friend',
    supportTickets: 'support-tickets'
  };


  public gender = [
    { id: 'male', name: 'Male' },
    { id: 'female', name: 'Female' }
  ];

  public addressTypes = [
    { id: 'residential', name: 'Home' },
    { id: 'commercial', name: 'Office/Commercial' }
  ];

  // public ordersStatus = {
  //   pending: 'New Order',
  //   quoted: 'Quoted Order',
  //   'partial-payment': 'Partial Payment',
  //   'artist-assigned': 'Guru Assigned',
  //   'shoot-done': 'Shoot Executed',
  //   'feedback-received': 'Feedback Received',
  //   completed: 'Completed',
  //   canceled: 'Cancelled'
  // };

  public ordersStatus = {
    pending: 'New Order',
    quoted: 'Payment Pending',
    // 'partial-payment': 'New Order',
    'artist-assigned': 'New Order',
    // 'shoot-done': 'New Order',
    'expired': "Expired",
    'feedback-received': 'Feedback Received',
    completed: 'Completed',
    canceled: 'Cancelled',
    // 'reschedule-requests': 'Reschedule Requests',
    // 'images-uploaded': 'Images Uploaded'
  };

  public orderEventType = {
    // new: 'New Order',
    quotation: 'Session charges & payment requested',
    quoted: 'Session charges & payment requested',
    're-quote': 'Session charges & payment requested',
    'approve-rescheduled': 'rescheduled',
    canceled: 'cancelled',
    rescheduled: 'Reschedule Request',
    'manual-payment': 'manual payment received',
    'partial-payment': 'partial payment received',
    'full-payment': 'full payment received',
    'image-view': 'images ready for viewing',
    'image-download': 'images ready for download',
    'artist-assigned': 'guru details'
  };

  public rezorPayOptions: any = {
    // "key": "rzp_live_1LiGARiCGpDC2n", // live key
    "key": "rzp_test_k65NGyP73xDOu3", // dev env key
    "amount": "29900", // 2000 paise = INR 20
    // "currency": "INR",
    "name": "Arham Vijja",
    "description": "subscription process",
    "image": "assets/img/logo.png",
    "prefill": {
      "name": "",
      "email": ""
    },
    "notes": {
      "address": "type address here"
    },
    "theme": {
      "color": "#F37254"
    },
    "modal": {
      "ondismiss": function () {
        localStorage.clear();
      }
    }
  };

  selectedCard: any;

  private activatedRouteSource = new BehaviorSubject(null);
  public currentActiveRoute = this.activatedRouteSource.asObservable();

  private categoryName = new BehaviorSubject('');
  public shareCategoryName = this.categoryName.asObservable();

  private activeCartItem = new BehaviorSubject(0);
  public currentCartItem = this.activeCartItem.asObservable();

  private activeSelectedItem = new BehaviorSubject('');
  public currentSelectedItem = this.activeSelectedItem.asObservable();

  private SelectedFavourObject = new BehaviorSubject({});
  public currentSelectedFavourObject = this.SelectedFavourObject.asObservable();

  private SelectedFavourArray = new BehaviorSubject([]);
  public currentSelectedFavourArray = this.SelectedFavourArray.asObservable();

  private loginPopUpShowHide = new BehaviorSubject('');
  public shareLoginPopupShowHide = this.loginPopUpShowHide.asObservable();

  private orderNumber = new BehaviorSubject('');
  public shareorderNumber = this.orderNumber.asObservable();

  private activeCartSelectedItem = new BehaviorSubject('');
  public currentCartSelectedItem = this.activeCartSelectedItem.asObservable();

  private getProfile = new BehaviorSubject('');
  public shareGetPrifile = this.getProfile.asObservable();

  private getCounter = new BehaviorSubject(0);
  public shareCounter = this.getCounter.asObservable();

  private redirectCart = new BehaviorSubject(false);
  public shareRedirctCart = this.redirectCart.asObservable();

  private cartOpen = new BehaviorSubject(false);
  public shareCartOpen = this.cartOpen.asObservable();

  private favouriteOpen = new BehaviorSubject(false);
  public shareFavouriteOpen = this.favouriteOpen.asObservable();

  private categoryNull = new BehaviorSubject(false);
  public sharecategoryNull = this.categoryNull.asObservable();

  private categoryNamevalue = new BehaviorSubject('');
  public shareCategoryNamevalue = this.categoryNamevalue.asObservable();

  private checkoutCart = new BehaviorSubject(false);
  public shareCheckoutCart = this.checkoutCart.asObservable();

  public calanderEvents = null;

  shareMenu = [
    { name: 'Facebook', flag: 'fb', icon: 'fa fa-facebook' },
    { name: 'Twitter', flag: 'twit', icon: 'fa fa-twitter' },
    { name: 'Linkedin', flag: 'linked', icon: 'fa fa-linkedin' },
    { name: 'Pinterest', flag: 'pin', icon: 'fa fa-pinterest' },
    { name: 'Whats app', flag: 'wp', icon: 'fa fa-whatsapp' },
    { name: 'Email', flag: 'email', icon: 'fa fa-envelope' },
    { name: 'Copy Link', flag: 'clip', icon: 'fa fa-clipboard' }
  ];

  constructor(
    public translate: TranslateService,
    private toastr: ToastrService,
    public router: Router
  ) {

    this.env = env;
    // console.log(env);

    this.rezorPayOptions.key = this.env.razorPayKey;
    // console.log(this.rezorPayOptions);

    // this.checkIsPackageItem();

    // this.setPreFixToLocalStorage();
    // console.log(this.localStorage);
  }

  // setPreFixToLocalStorage() {
  //   Object.keys(this.localStorage).forEach((el) => {
  //     this.localStorage[el] = `${this.prefix}-${this.localStorage[el]}`;
  //   });
  // }

  storeEvents(value) {
    this.calanderEvents = value || null;
    // console.log(this.calanderEvents);
  }

  isCheckOut(value) {
    this.checkoutCart.next(value);
  }

  checkoutValue() {
    return this.shareCheckoutCart;
  }

  addedItem(data) {
    this.activeSelectedItem.next(data);
  }

  addFavouriteInObject(data) {
    this.SelectedFavourObject.next(data);
  }

  addFavouriteInArray(data) {
    this.SelectedFavourArray.next(data);
  }

  currentRoute(route) {
    this.activatedRouteSource.next(route);
  }

  setCurrentCartItem(data) {
    this.activeCartSelectedItem.next(data);
    return false;
  }

  changeCartLength() {
    const item = this.getCartItem();
    this.activeCartItem.next(item);
  }

  success(message) {
    this.toastr.success(message, this.labels.success);
  }

  error(message) {
    this.toastr.error(message, 'Alert!');
  }

  validateFile(name, type): boolean {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    switch (type) {
      case 'image':
        if (this.photoExtensions.indexOf(ext.toLowerCase()) > -1) {
          return true;
        }
        break;
      case 'doc':
        if (this.docExtensions.indexOf(ext.toLowerCase()) > -1) {
          return true;
        }
        break;
      case 'pdf':
        if (this.pdfExtensions.indexOf(ext.toLowerCase()) > -1) {
          return true;
        }
        break;
      case 'text':
        if (this.textFiles.indexOf(ext.toLowerCase()) > -1) {
          return true;
        }
        break;
      default:
        return false;

    }
    return false;
  }

  serverMessageHandler(objError: any) {
    let errMsg = '';
    if (objError.error && objError.error.errors && objError.error.errors[0]) {
      const objErr = objError.error.errors[0];
      if (objErr.code) {
        let multiPartFounded = false;
        const objDynamicPart = [];
        if (objErr.data) {
          const arrObjKeys = Object.keys(objErr.data);
          if (arrObjKeys.length > 0) {
            multiPartFounded = true;
            arrObjKeys.forEach((dataKey) => {
              const objData = {};
              objData[dataKey] = objErr.data[dataKey];
              objDynamicPart.push(objData);
            });
            errMsg = this.translate.instant('SERVERMSG.' + objErr.code, ...objDynamicPart);
          }
        }
        if (!multiPartFounded) {
          errMsg = this.translate.instant('SERVERMSG.' + objErr.code);
        }
      } else {
        errMsg = objErr.msg;
      }
    } else {
      errMsg = this.translate.instant('LOAD.ERROR.API_FAIL_ISSUE');
    }

    if (errMsg === 'Looks like you are not signed in.') {
      // this.changeHeaderFun(true);
      // this.router.navigate([this.routeUrl.index]);
      return 0;
    } else {
      this.toastr.error(errMsg, 'Alert!');
    }

  }

  setCategoryName(name: string) {
    this.categoryName.next(name);
  }

  getCategoryName() {
    return this.shareCategoryName;
  }

  getCartItem() {
    const result = JSON.parse(localStorage.getItem(this.lsPrefix + this.localStorage.items));
    if (!result) {
      return 0;
    }
    this.cartLength = result.length;
    return this.cartLength;
  }

  getSorting(flag): string {
    return flag ? 'desc' : 'asc';
  }

  checkTokenValidity() {
    const accessToken = localStorage.getItem(this.lsPrefix + this.localStorage.accessToken);
    if (!accessToken) {
      return false;
    } else {
      return true;
    }
  }

  setOrderNumber(num) {
    this.orderNumber.next(num);
  }

  dropDownShowHide(value) {
    this.loginPopUpShowHide.next(value);
  }

  setSelectedCard(data) {
    this.selectedCard = data;
  }

  getSelectedCard() {
    return this.selectedCard;
  }

  setProfile(data) {
    this.getProfile.next(data);
  }

  setCounterValue(value) {
    value++;
    if (value === 2) {
      this.counter++;
    } else {
      this.counter = 0;
    }
    this.counterCheck();
    this.getCounter.next(value);
  }

  counterCheck() {
    // console.log('counter ' + this.counter);
    return this.counter;
  }

  // checkIsPackageItem() {
  //   let flag = false;
  //   const item = JSON.parse(localStorage.getItem(this.lsPrefix + this.localStorage.items));
  //   // console.log(item);

  //   if (item) {
  //     item.forEach((element) => {
  //       if (element.is_package === 1) {
  //         flag = true;
  //       } else {
  //         flag = false;
  //       }
  //     });
  //     // console.log(flag);
  //   }

  //   if (flag) {
  //     this.setCounterValue(1);
  //   } else {
  //     this.setCounterValue(0);
  //   }
  // }

  // isPackgeCheck(value) {
  //   if (value === 1) {
  //     this.checkIsPackageItem();
  //   }
  // }

  redirectCartFun(value) {
    this.redirectCart.next(value);
  }

  useOfImagesConvert(data, convert) {
    const useOfImagesArray = [
      'online_sharing', 'e_commerce_digital_marketing',
      'catalog_album_printing', 'bill_boards_flex', 'packaging_material', 'other_field'
    ];
    if (!data) { return data; }
    if (convert === 'object') {
      const values = {};
      data.use_of_image = data.use_of_image || [];
      useOfImagesArray.forEach(element => {
        values[element] = (data.use_of_image.indexOf(element) > -1) ? 1 : 0;
      });
      return values;
    }
    if (convert === 'array') {
      data.use_of_image = [];
      useOfImagesArray.forEach(element => {
        if (data[element]) {
          if (element === 'other_field') {
            data.use_of_image.push(data.other_field_value);
          } else {
            data.use_of_image.push(this.useImageObj[element]);
          }
        }
        delete data[element];
      });
      return data;
    }
  }

  cartPopupOpen(value) {
    this.cartOpen.next(value);
  }

  favouritePopupOpen(value) {
    this.favouriteOpen.next(value);
  }

  smoothScroll() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  setCategory(value) {
    this.categoryNull.next(value);
  }

  setCategoryNameValue(value) {
    this.categoryNamevalue.next(value);
  }

  serverMessageForAuth(objError: any) {
    let errMsg = '';
    if (objError.error && objError.error.errors && objError.error.errors[0]) {
      const objErr = objError.error.errors[0];
      if (objErr.code) {
        let multiPartFounded = false;
        const objDynamicPart = [];
        if (objErr.data) {
          const arrObjKeys = Object.keys(objErr.data);
          if (arrObjKeys.length > 0) {
            multiPartFounded = true;
            arrObjKeys.forEach((dataKey) => {
              const objData = {};
              objData[dataKey] = objErr.data[dataKey];
              objDynamicPart.push(objData);
            });
            errMsg = this.translate.instant('SERVERMSG.' + objErr.code, ...objDynamicPart);
          }
        }
        if (!multiPartFounded) {
          errMsg = this.translate.instant('SERVERMSG.' + objErr.code);
        }
      } else {
        errMsg = objErr.msg;
      }
    } else {
      errMsg = this.translate.instant('LOAD.ERROR.API_FAIL_ISSUE');
    }
    // this.toastr.error(errMsg, 'Alert!');
    return errMsg;
  }

  // redirectTo(pathName) {
  //   switch (pathName) {
  //     case 'step-3':
  //       // navigate to step 3
  //       this.router.navigate([this.routeUrl.my_cart]);
  //       break;
  //   }
  // }

  checkOrderStatus(objList) {
    objList.forEach((el) => {
      if (new Date(el.items[0].selected_date).getTime() < new Date().getTime() && el.is_payment_done == 0) {
        el.order_status = 'expired';
      }
      else if (el.order_status == 'quoted' && new Date(el.items[0].selected_date).getTime() > new Date().getTime() && el.is_payment_done == 0) {
        el.order_status = 'pending';
      }
      else if (el.order_status == 'quoted' && new Date(el.items[0].selected_date).getTime() > new Date().getTime() && el.is_payment_done == 1) {
        el.order_status = 'artist-assigned';
      }
      else if (el.order_status == 'quoted' && el.is_payment_done == 1) {
        el.order_status = 'completed';
      }
      else {
        el.order_status = el.order_status;
      }
    });
    return objList;
  }

  // changeHeaderFun(value) {
  //   this.changeHeader.next(value);
  // }

  calculateAmountPending(objList) {
    objList.forEach((ele) => {
      if (!isNaN(ele.quoted_price) && !isNaN(ele.amount_paid)) {
        if (ele.is_payment_done === 1) {
          ele.amount_pending = 0;
        } else {
          ele.amount_pending = ele.quoted_price - ele.amount_paid;
          if (ele.amount_pending < 0) {
            ele.amount_pending = 0;
          }
        }
      }
    });
    return objList;
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.success(this.translate.instant('LOAD.SUCCESS.COPY_CLIPBOARD'));
  }

  getTitleName(value) {
    // console.log(this.nativeWindow.titleConfig);
    if (value === 'step-1') {
      return this.nativeWindow.titleConfig.step1 || this.title;
    }
    if (value === 'step-2') {
      return this.nativeWindow.titleConfig.step2 || this.title;
    }
    if (value === 'faqs') {
      return this.nativeWindow.titleConfig.faqs || this.title;
    }
    if (value === 'my-fav') {
      return this.nativeWindow.titleConfig.my_fav || this.title;
    }

  }

  shareGalleryImages(url, flag) {
    // console.log(url);
    // console.log(flag);
    switch (flag) {
      case 'fb':
        //facebook shareable link
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + url.trim(), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'twit':
        //twitter shareable link
        window.open('https://twitter.com/intent/tweet?text=' + url.trim() + '&related=soc,micropat', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'linked':
        //linked shareable link
        window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url.trim() + '&title=soc&summary=articleSummary&source=' + this.env.apiUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'pin':
        //pinterest shareable link
        window.open('https://pinterest.com/pin/create/button/?url=' + url.trim() + '&media=&description=soc', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'wp':
        //whats app shareable link
        window.open('https://api.whatsapp.com/send?text=' + url.trim() + '&media=&description=soc', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'email':
        //email shareable link
        window.open('https://www.addtoany.com/add_to/email?linkurl=' + url.trim() + '%2F&linkname=Studio On Cloud Share%20Images&linknote=socindia', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        break;
      case 'clip':
        this.copyToClipboard(url);
        break;
    }
  }

  returnString(array) {
    // console.log(typeof array);
    let tempString = '';
    if (array && array.length > 0 && typeof array === 'object') {
      tempString = array.join(', ');
    }
    return tempString;
  }

  setTempValue(value) {
    this.tempCategory = value;
  }

  returnTempValue() {
    return this.tempCategory;
  }

  setDate(time) {
    let hr = time.split(":")[0];
    let min = time.split(":")[1];
    return `${this.padZero(hr)}:${this.padZero(min)}`;
  };

  padZero(value) {
    if (value && Number(value) < 10) {
      return `0${value}`;
    }
    return value;
  };

}
