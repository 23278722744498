import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiHandlerService } from '../api-handler/api-handler.service';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CartServiceService {

  items = [];
  constructor(public apiHandler: ApiHandlerService,
    public _configs: AppConfigsService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public router: Router,
    private auth: AuthService) {
  }

  updateCart() {
    // console.log('called');
    //cart update to server side
    let cart: any = {
      complete_package: 1,

      //guru
      selected_mentor: '',
      selected_date: '', //date
      selected_time: '', //time

      //cart
      course_package: [],
      remarks: '',
      estimated_sub_total: 0,
      term_and_condition: 0,
      referral_code: '',
      usage_package: [],
      items: [],
      date_range: null
    };

    cart.term_and_condition = 0;

    let queryCall = null;
    let cartData: any = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItems));

    if (!cartData) {
      cartData = cart;
      cartData.items = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    }

    cartData.order_status = 'cart';

    if (cartData && cartData.id) {
      queryCall = this.apiHandler.updateOrders(cartData, this._configs.apiKeys.orders, cartData.id);
    } else {
      queryCall = this.apiHandler.createOrders(cartData, this._configs.apiKeys.orders);
    }

    queryCall.subscribe((data: any) => {
      cartData.id = data.item.id;
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cartData));
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(cartData.items));
      this._configs.changeCartLength();
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });

  }

}
