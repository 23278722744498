import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalCookieComponent } from './shared/components/modal-cookie/modal-cookie.component';
import { AppConfigsService } from './shared/services/app-configs/app-configs.service';

// declare ga as a function to set and sent the events
// declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Arham Vijja';

  modalRef: BsModalRef;

  constructor(
    public httpService: HttpClient,
    public _configs: AppConfigsService,
    public router: Router,
    public modalService: BsModalService
  ) {

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });

  }

  ngOnInit(): void {
    this.callEnJson();
    setTimeout(() => {
      this.callEnJson();
    }, 1000);

    // this.openCookiesModal();
  }

  callEnJson() {
    this.httpService.get('./assets/i18n/en.json').subscribe(
      (data: any) => {
        // console.log(data);
      },
      (err: HttpErrorResponse) => {
        this._configs.serverMessageHandler(err);
      }
    );
  }

  async openCookiesModal() {

    // const accepted = await cookieStore.get(this._configs.cookiePreFix + 'cookie_accepted');
    // if (!accepted || !accepted.value || accepted.value !== 'yes') {
    //   this.modalRef = this.modalService.show(ModalCookieComponent, {
    //     class: 'modal-md',
    //     initialState: '',
    //     backdrop: true,
    //     ignoreBackdropClick: false,
    //     animated: false
    //   });
    //   this.modalRef.content.onClose = (flagConfirm) => {
    //     this.modalRef.hide();
    //   };
    // }
  }

}


