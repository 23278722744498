import { Component, OnInit, ɵConsole } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-cart-delete',
  templateUrl: './modal-cart-delete.component.html',
  styleUrls: ['./modal-cart-delete.component.scss']
})
export class ModalCartDeleteComponent implements OnInit {
  onClose: any;
  data: any = {
    title: 'this'
  };
  name;
  confirmMessage: any;
  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef,

  ) {
  }

  ngOnInit() {
    this.confirmMessage = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(this.confirmMessage, {
      name: this.data.title
    }));
  }
}
