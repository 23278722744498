import { Directive, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef) {
  }

  @Output() public appClickOutside = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])

  @HostListener('document:keydown.escape', ['$event'])

  // public onClick(targetElement) {
  //   const clickedInside = this._elementRef.nativeElement.contains(targetElement);
  //   if (!clickedInside) {
  //     this.appClickOutside.emit();
  //   }
  // }

  // public onMouseDown(targetElement) {
  //   console.log('click');
  //   const clickedInside = this._elementRef.nativeElement.contains(targetElement);
  //   if (!clickedInside) {
  //     this.appClickOutside.emit();
  //   }
  // }

  onEvent(event) {
    // console.log(event.keyCode);
    // console.log(this._elementRef.nativeElement);
    // this.appClickOutside.emit();
    const clickedInside = this._elementRef.nativeElement.contains(event.target);

    if (!clickedInside) {
      // console.log('click');
      this.appClickOutside.emit();
    }

    if (event.keyCode === 27) {
      // console.log('esc');
      this.appClickOutside.emit();
    }

  }

  // public onkeydown(event) {
  //   console.log(event.keyCode);
  //   const click = this._elementRef.nativeElement.contains(event);
  //   if (!click) {
  //     this.appClickOutside.emit();
  //   }
  // }

}
