import { Directive, Input } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appMatchPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }]
})
export class MatchPasswordDirective {
  @Input('appMatchPassword') mustMatchStr: string[] = [];

  validate(formGroup: FormGroup): ValidationErrors {
    // console.log(this.mustMatchStr , "calledd");
    // console.log(this.MustMatch(this.mustMatchStr[0], this.mustMatchStr[1])(formGroup));
    return this.MustMatch(this.mustMatchStr[0], this.mustMatchStr[1])(formGroup);
  };

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      // return null if controls haven't initialised yet
      if (!control || !matchingControl) {
        return null;
      }

      // return null if another validator has already found an error on the matchingControl
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      };

    }
  };

}


