import { Component, OnInit, Input, Output, ChangeDetectorRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigsService } from 'src/app/shared/services/app-configs/app-configs.service';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginatiomComponent implements OnInit {

  transShowing; transTo; transOf; transEntries;
  maxSize = 3; // Limit the maximum visible page numbers
  boundaryLinks = true; // set first and last button if it is true, Default = false
  page = 1; // current page/active page
  changeLimit: number;

  @Input() loaded = false; // check if content is being loaded
  @Input() perPage: number; // how many items we want to show per page
  @Input() skipItem: number; // how many items we want to skip
  @Input() count = 0; // how many total items there are in all pages
  @Output() skip = new EventEmitter(); // return page number of pagination

  constructor(public configs: AppConfigsService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef) {

    this.perPage = +this.configs.itemsPerPage;
    this.changeLimit = +this.configs.itemsPerPage;

    this.transShowing = translate.instant('LOAD.CONTENT.SHOWING');
    this.transTo = translate.instant('LOAD.CONTENT.TO');
    this.transOf = translate.instant('LOAD.CONTENT.OF');
    this.transEntries = translate.instant('LOAD.CONTENT.ENTRIES');
  }

  ngOnInit() {
  }

  /*Lifecycle hook that is called after every check of a component's view*/
  ngAfterViewChecked() {
    if (this.changeLimit !== this.perPage) {
      this.page = 1;
      this.changeLimit = this.perPage;
      this.pageChanged({ page: this.page, itemsPerPage: this.perPage });
    }

    // console.log(this.skipItem);

    if (this.skipItem === 0) {
      this.page = 1;
    };

    this.cdRef.detectChanges();
  }

  // Use for get 'From' value out of items
  getMin(): number {
    if (this.count === this.perPage) {
      this.page = 1;
      return 1;
    }
    if (this.count === 0) { return 0; }
    return ((this.perPage * this.page) - this.perPage) + 1;
  }

  // Use for get 'To' value out of items
  getMax(): number {
    let max = this.perPage * this.page;
    if (max > this.count) {
      max = this.count;
    }
    return max;
  }

  // Used to emit the skipItems
  pageChanged(event: any): void {
    this.skip.emit({
      skip: (event.page - 1) * this.perPage,
      limit: this.perPage,
      currentPage: event.page
    });
  }

}



