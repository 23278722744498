import { Component, OnInit } from '@angular/core';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';

@Component({
  selector: 'app-modal-cookie',
  templateUrl: './modal-cookie.component.html',
  styleUrls: ['./modal-cookie.component.scss']
})
export class ModalCookieComponent implements OnInit {

  onClose: any;

  constructor(public _configs: AppConfigsService) {
  }

  ngOnInit() {
  }

  acceptCook() {
    cookieStore.set(this._configs.cookiePreFix + 'cookie_accepted', 'yes');
    // cookieStore.set('cookie_accepted', JSON.stringify('yes'), 30, 'this.location.path', '' , false, 'Strict');
    // cookieStore.set({name:'cookie_accepted',value:'yes',expires:null,path:'/',domain:null,secure:false,sameSite:'none'});
    this.onClose(true);
  }

  cancelCook() {
    this.onClose(false);
  }

}
