import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';

@Component({
  selector: 'app-faqs-list',
  templateUrl: './faqs-list.component.html',
  styleUrls: ['./faqs-list.component.scss']
})
export class FAQsListComponent implements OnInit {
  dataLoaded = false;
  dataLoading = false;
  queryParams;
  FAQList = [];

  constructor(
    public apiHandler: ApiHandlerService,
    public _configs: AppConfigsService,
    private sanitizer: DomSanitizer,
    private _title: Title
  ) {
    this.queryParams = {
      sortBy: 'sorting_order',
      sort: 'asc'
    };
  }

  ngOnInit() {
    // this.setTitle();
    this.getFAQList();
  }

  getFAQList() {
    this.dataLoaded = false;
    this.dataLoading = true;
    this.apiHandler.callCategoryList(this.queryParams, this._configs.apiKeys.faqs).subscribe((data: any) => {
      this.FAQList = [];
      data.list.forEach(item => {
        item.safeHTML = this.sanitizer.bypassSecurityTrustHtml(item.answer);
        this.FAQList.push(item);
      });
      this.dataLoaded = true;
      this.dataLoading = false;
    }, (err) => {
      this.dataLoaded = true;
      this.dataLoading = false;
      this._configs.serverMessageHandler(err);
    });
  }

  // setTitle() {
  //   let title = this._configs.getTitleName('faqs');
  //   if (title) {
  //     this._title.setTitle(title);
  //   }
  // }

}
