import { Injectable, NgModule } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

@NgModule({
  imports: [TranslateModule]
})
export class AppInterceptorService {

  constructor(
    public translate: TranslateService,
    public _configs: AppConfigsService,
    private _auth: AuthService,
    private _tostr: ToastrService,
    private router: Router) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    if (!!accessToken) {
      this._auth.lastInteractionSession(); // check every time that user is idle for long time.
      // clone and modify the request

      //without pre fix
      // this._configs.localStorage.accessToken = 'x-access-token';

      request = request.clone({
        setHeaders: {
          [this._configs.localStorage.accessToken]: accessToken,
          // [this._configs.localStorage.xTimeZone]: new Date().getTimezoneOffset().toString(),
          // ['Access-Control-Allow-Origin']: '*',
          // ['Access-Control-Allow-Methods']: 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          // ['Access-Control-Allow-Headers']: 'X-Requested-With,content-type, x-access-token, x-time-zone',
          // ['Access-Control-Allow-Credentials']: true
        }
      });
      // add a header for multilingual
      // const customReq = request.clone({
      //   headers: request.headers.set()
      // });
    }

    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        if (response instanceof HttpResponse) {

          if (response.status === 401) {
            localStorage.clear();
            this.router.navigate(['/']);
          }
        }
      }, (error) => {
        if (error.status === 0) {
          error.error = {
            errors: [{
              msg: this.translate.instant('LOAD.ERROR.UNABLE_TO_CONNECT'),
              param: null
            }]
          };
        } else if (error.status === 401) {
          localStorage.clear();
          this.router.navigate([this._configs.routeUrl.auth]);
        } else if (error.status === 403) {
          localStorage.clear();
          this._tostr.error(this.translate.instant('LOAD.ERROR.PERMISSION_DENIED_MSG'));
          this.router.navigate([this._configs.routeUrl.auth]);
        }
      })
    );
  }
}
