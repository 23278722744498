import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { AddCartRestrictComponent } from '../add-cart-restrict/add-cart-restrict.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Lightbox } from 'ngx-lightbox';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalSelectActionComponent } from '../modal-select-action/modal-select-action.component';
import { ModalConfirmFavouriteComponent } from '../modal-confirm-favourite/modal-confirm-favourite.component';
import { AuthService } from '../../services/auth/auth.service';
import { CartServiceService } from '../../services/cart-service/cart-service.service';

@Component({
  selector: 'app-carousel-items',
  templateUrl: './carousel-items.component.html',
  styleUrls: ['./carousel-items.component.scss']
})
export class CarouselItemsComponent implements OnInit {
  bsModalRefModal: BsModalRef;
  bsFavouriteModal: BsModalRef;
  favouriteList = [];
  private album = [];
  allowToPushCart = 0;
  counter = 0;
  cartAddRemove = false;
  bsModalRef: BsModalRef;
  isAddCartShow = false;
  favouriteObj: any = [];
  dataLoaded = false;
  dataLoading = false;
  items = [];
  relatedImages: any = [];
  customOptions: OwlOptions = {

    loop: true,
    // autoHeight: true,
    nav: true,
    dots: false,
    // navSpeed: 700,
    // center: true,
    margin: 25,
    URLhashListener: true,
    // startPosition: 'URLHash',
    navText: ['<i class="arhamvijja-arrow-left"></i>', '<i class="arhamvijja-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
  };

  constructor(
    private apiHandler: ApiHandlerService,
    public translate: TranslateService,
    public _configs: AppConfigsService,
    private modalService: BsModalService,
    private router: Router,
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private cartService: CartServiceService
  ) {
    this.checkLocalStorage('relatedImage');
  }

  ngOnInit() {
    let cartItemIds = [];
    this._configs.currentCartSelectedItem.subscribe((element: any) => {
      if (element && element.length > 0) {
        cartItemIds = element.map(a => a.sub_category_id);
      }
    });
    if (cartItemIds.length > 0) {
      this.getRelatedItems(cartItemIds);
    }

    this._configs.currentCartItem.subscribe((data: any) => {
      this.checkAlreadydAddedCart();
    });

    // this._configs.checkIsPackageItem();

  }

  checkLocalStorage(type) {
    let itemIds;
    const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    // this._configs.changeCartLength();
    if (result === null) {
      this.items = [];
    } else {
      this.items = result;
      if (type === 'relatedImage') {
        itemIds = this.items.map(a => a.sub_category_id);
        this.getRelatedItems(itemIds);
      }

    }
  }

  getRelatedItems(itemIds) {
    // console.log(itemIds);
    if (itemIds && itemIds.length > 0) {
      this.dataLoaded = false;
      this.dataLoading = true;
      this.apiHandler.getRelatedImages({ ids: itemIds }, this._configs.apiKeys.sub_categories).subscribe((data: any) => {
        this.relatedImages = data.list;
        // console.log(this.relatedImages);
        // video changes--r@j
        this.relatedImages.forEach(el => {
          if (el.images) {
            try {
              el.images = JSON.parse(el.images);
            } catch (error) {

            }
          }
          if (el.video_url && el.video_url !== '') {
            el.video_url_trusted = this.sanitizer.bypassSecurityTrustResourceUrl(el.video_url);
          }
        });
        this.setLightBox();
        this.checkAlreadydAddedCart();
        const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
        if (accessToken) {
          this.getFavourites();
        }
        this.dataLoaded = true;
        this.dataLoading = false;
      }, (err) => {
        this.dataLoaded = true;
        this.dataLoading = false;
        // Setup localization server massage
        this._configs.serverMessageHandler(err);
      });
    } else {
      this.dataLoaded = true;
      this.relatedImages = [];
    }
    // console.log(this.relatedImages);
  }


  addToFavourites(data) {
    let strMsg;
    const favourite = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItems));
    let favouriteItemArray = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
    if (favouriteItemArray === null) {
      favouriteItemArray = [];
    }
    data.isLike = !data.isLike;
    let fType;
    if (data.isLike) {
      fType = 'favourite';
      strMsg = this.translate.instant('LOAD.SUCCESS.FAVOURITE_SUCCESS');
    } else {
      fType = 'unfavourite';
      strMsg = this.translate.instant('LOAD.SUCCESS.UN_FAVOURITE_SUCCESS');
    }
    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    if (accessToken) {
      if (!data.isLike) {
        this.apiHandler.addToFavourites({}, this._configs.apiKeys.profile, data.id).subscribe(() => {
          this.getFavourites();
          let index = -1;

          this.favouriteList.filter((el, i) => {
            if (el.item.id === data.id && index === -1) {
              index = i;
            }
          });
          if (index < 0) {
            this.favouriteList.push(data);
          }
          localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(this.favouriteList));
          this._configs.addFavouriteInArray(this.favouriteList);
          this._configs.success(strMsg);
        }, (err) => {
          this._configs.serverMessageHandler(err);
        });

        // this.bsFavouriteModal = this.modalService.show(ModalConfirmFavouriteComponent, {
        //   class: 'modal-md modal-dialog-centered',
        //   backdrop: true,
        //   ignoreBackdropClick: false
        // });
        // this.bsFavouriteModal.content.onClose = (flagConfirm) => {
        //   if (flagConfirm === true) {
        //     this.apiHandler.addToFavourites({}, this._configs.apiKeys.profile, data.id).subscribe(() => {
        //       this.getFavourites();
        //       let index = -1;

        //       this.favouriteList.filter((el, i) => {
        //         if (el.item.id === data.id && index === -1) {
        //           index = i;
        //         }
        //       });
        //       if (index < 0) {
        //         this.favouriteList.push(data);
        //       }
        //       localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(this.favouriteList));
        //       this._configs.addFavouriteInArray(this.favouriteList);
        //       this._configs.success(strMsg);
        //     }, (err) => {
        //       this._configs.serverMessageHandler(err);
        //     });

        //   }
        //   // this._configs.addFavouriteInArray(favouriteItemArray);
        //   this.bsFavouriteModal.hide();
        // };
      } else {
        this.apiHandler.addToFavourites({}, this._configs.apiKeys.profile, data.id).subscribe(() => {
          this.getFavourites();
          let index = -1;

          this.favouriteList.filter((el, i) => {
            if (el.item.id === data.id && index === -1) {
              index = i;
            }
          });
          if (index < 0) {
            this.favouriteList.push(data);
          }
          localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(this.favouriteList));
          this._configs.addFavouriteInArray(this.favouriteList);
          this._configs.success(strMsg);
        }, (err) => {
          this._configs.serverMessageHandler(err);
        });


      }

    } else {
      if (favouriteItemArray === null) {
        favouriteItemArray = [];
      }
      if (favourite && favourite.length > 0 && favouriteItemArray && favouriteItemArray.length > 0) {
        this.favouriteObj = JSON.parse(JSON.stringify(favourite));
        favouriteItemArray = JSON.parse(JSON.stringify(favouriteItemArray));
      }
      if (this.favouriteObj.length > 0 && favouriteItemArray.length > 0) {
        let index = -1;
        let ind = -1;
        this.favouriteObj.filter((el, ind) => {
          if (el.item_id === data.id && index === -1) {
            index = ind;
          }
        });
        favouriteItemArray.filter((el, i) => {
          if (el.item.id === data.id && ind === -1) {
            ind = i;
          }
        });
        if (index < 0 && ind < 0) {
          this.favouriteObj.push(
            { item_id: data.id, type: fType }
          );
          favouriteItemArray.push({ item: data });
        } else {
          this.bsFavouriteModal = this.modalService.show(ModalConfirmFavouriteComponent, {
            class: 'modal-md modal-dialog-centered',
            backdrop: true,
            ignoreBackdropClick: false
          });
          this.bsFavouriteModal.content.onClose = (flagConfirm) => {
            if (flagConfirm === true) {
              this.favouriteObj[index].type = fType;
              this.favouriteObj.splice(index, 1);
              favouriteItemArray.splice(ind, 1);
              // localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItems, JSON.stringify(this.favouriteObj));
              // localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(favouriteItemArray));
              // this._configs.addFavouriteInArray(favouriteItemArray);
            }
            // this._configs.addFavouriteInArray(favouriteItemArray);
            this.bsFavouriteModal.hide();
          };
        }
      } else {
        this.favouriteObj.push(
          { item_id: data.id, type: fType }
        );
        favouriteItemArray.push({ item: data });
      }
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItems, JSON.stringify(this.favouriteObj));
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(favouriteItemArray));
      this._configs.addFavouriteInArray(favouriteItemArray);
    }
    if (fType === 'favourite') {
      this._configs.favouritePopupOpen(true);
    }
  }

  setLightBox() {
    this.relatedImages.forEach((data) => {
      if (data.default_img !== '') {
        const src = data.default_img;
        const albumObj = {
          src: src
        };
        this.album.push(albumObj);
      }

    });
  }

  addToCart(data: any) {
    data.cartAddRemove = !data.cartAddRemove;
    const cartType = data.cartAddRemove ? 'cartAdd' : 'cartRemove';
    // const eligbleFlag = this.checkCardIsEligible(data);
    // const packageFlag = this.checkPackage(data);
    if (cartType === 'cartAdd') {
      // if (packageFlag) {
      this.checkLocalStorageItems();
      // this.allowToPush(data);
      // this._configs.checkIsPackageItem();
      // const tempCounter = this._configs.counterCheck();
      // // && this.allowToPushCart < 1
      // if (tempCounter < 1) {
      // console.log('s - 1');

      // if (this.items.length < 1) {

      this.items.push(data);
      this._configs.addedItem(this.items);
      const cartItems: any = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItems));
      if (cartItems && cartItems.items !== null) {
        cartItems.items = this.items;
        localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cartItems));
      }
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(this.items));
      this._configs.changeCartLength();

      this._configs.cartPopupOpen(true);
      this._configs.smoothScroll();
    }

    if (cartType === 'cartRemove') {

      this.checkLocalStorageItems();
      const id = data.id;
      const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
      result.forEach((element, i) => {
        if (id === element.id) {
          result.splice(i, 1);
        }
        i++;
      });
      const cartItems = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItems));
      if (cartItems && cartItems.items !== null) {
        // cartItems.items.forEach((element, i) => {
        //   if (id === element.id) {
        //     cartItems.items.splice(i, 1);
        //   }
        //   i++;
        // });
        cartItems.items = result;

        localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cartItems));
        this._configs.changeCartLength();
      }

      this._configs.addedItem(result);
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(result));
      // this._configs.addedItem(result);
      this._configs.changeCartLength();

      this.checkLocalStorageItems();

      if (result.length === 0) {
        this.router.navigate([this._configs.routeUrl.index]);
      }
      // this._configs.smoothScroll();
    }

    let token = this.auth.getToken();

    if (token) {
      this.cartService.updateCart();
    }

    // } else {
    //   this._configs.setSelectedCard(data);
    //   this._configs.dropDownShowHide(true);
    // }
  }

  checkLocalStorageItems() {
    const cartItems = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItems));
    if (cartItems) {
      this.items = cartItems.items;
    } else {
      const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
      if (result === null) {
        this.items = [];
      } else {
        this.items = result;
      }
    }
  }

  checkAlreadydAddedCart() {
    this.checkLocalStorageItems();
    if (this.items.length === 0) {
      return 0;
    } else {
      this.relatedImages.forEach((element, i) => {
        element.cartAddRemove = false;
        this.items.forEach((e) => {
          if (element.id === e.id) {
            element.cartAddRemove = true;
          }
        });

        i++;
      });
    }
  }

  getFavourites() {
    this.apiHandler.callFavoutitesList({}, this._configs.apiKeys.profile).subscribe((data: any) => {
      this.favouriteList = data.list;
      this.relatedImages.forEach(el => {
        this.favouriteList.forEach(res => {
          if (el.id === res.item_id) {
            el.isLike = true;
          }
        });
      });

    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  openImage(index: number): void {
    // this._lightbox.open(this.album, index, { wrapAround: true, showImageNumberLabel: true });
  }

  // checkCardIsEligible(data) {
  //   this.checkLocalStorageItems();
  //   let counterText = 0;
  //   let counterTimeRange = 0;
  //   this.items.forEach((element) => {
  //     if (element.category.how_much_type === 'text') {
  //       counterText++;
  //     }

  //     if (element.category.how_much_type === 'time-range') {
  //       counterTimeRange++;
  //     }

  //   });

  //   if (counterText > 0 && data.category.how_much_type === 'time-range') {
  //     return false;
  //   } else if (counterTimeRange > 0 && data.category.how_much_type === 'text') {
  //     return false;
  //   } else {
  //     return true;
  //   }

  // }

  // checkPackage(data) {
  //   this.checkLocalStorageItems();
  //   let counterText = 0;
  //   let counterTimeRange = 0;
  //   this.items.forEach((element) => {
  //     if (element.is_package === 0) {
  //       counterText++;
  //     }

  //     if (element.is_package === 1) {
  //       counterTimeRange++;
  //     }

  //   });

  //   if (counterText > 0 && data.is_package === 1) {
  //     return false;
  //   } else if (counterTimeRange > 0 && data.is_package === 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  openModel(data) {
    this.bsModalRefModal = this.modalService.show(ModalSelectActionComponent, {
      class: 'modal-md modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: false
    });
    this.bsModalRefModal.content.onClose = (flagConfirm) => {
      if (flagConfirm === true) {
        // this.checkAlreadydAddedCart();
        // console.log(data);
        if (data.cartAddRemove === true) {
          this.bsModalRefModal.hide();
        } else {
          this.addToCart(data);
        }
        // this._configs.cartPopupOpen(true);
      } else if (flagConfirm === false) {
        this.bsModalRefModal.hide();
      } else if (flagConfirm === 'maybe') {
        if (data.isLike === true) {
          this.bsModalRefModal.hide();
        } else {
          this.addToFavourites(data);
        }
      }
      this.bsModalRefModal.hide();
    };
  }

}
