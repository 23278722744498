import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StarRatingComponent } from 'ng-starrating';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';

@Component({
  selector: 'app-modal-feedback',
  templateUrl: './modal-feedback.component.html',
  styleUrls: ['./modal-feedback.component.scss']
})
export class ModalFeedbackComponent implements OnInit {
  onClose: any;
  dataLoaded = false;
  dataLoading = false;
  item;
  title: string;
  formData;
  closeBtnName: string;
  starRatings;
  starConfigs;
  starConfigsOverall;
  translatePhotographer; translateOverall; translateReview; translateWebsite; translateOrder;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public bsModalRef: BsModalRef,
    public translate: TranslateService,
    private apiHandler: ApiHandlerService,
    public _configs: AppConfigsService

  ) {
    this.translatePhotographer = this.translate.instant('LOAD.FORM.PHOTOGRAPHER');
    this.translateOverall = this.translate.instant('LOAD.FORM.OVERALL');
    this.translateReview = this.translate.instant('LOAD.FORM.REVIEW');
    this.translateWebsite = this.translate.instant('LOAD.FORM.WEBSITE');
    this.translateOrder = this.translate.instant('LOAD.FORM.ORDER');
  }

  ngOnInit() {
    this.starRatings = {
      starConfigs: {
        totalStar: 10,
        selectedValue: 0
      },
      starConfigsOverall: {
        totalStar: 10,
        selectedValue: 0
      },
      starWebsiteConfigs: {
        totalStar: 10,
        selectedValue: 0
      }
    };

    this.initData();
  }
  initData() {
    this.formData = {
      website_experience: '',
      photographer_experience: '',
      overall_experience: '',
      order_id: this.item.id,
      description: ''
    };
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }, value) {
    if (value === 'photographer') {
      this.formData.photographer_experience = $event.newValue;
    } else if (value === 'overall') {
      this.formData.overall_experience = $event.newValue;
    } else if (value === 'website') {
      this.formData.website_experience = $event.newValue;
    }
  }
  submitData(submitForm) {
    submitForm.submitted = true;
    if (submitForm.valid) {
      this.dataLoaded = false;
      this.dataLoading = true;
      this.apiHandler.callCreateQuery(this.formData, this._configs.apiKeys.account).subscribe((data: any) => {
        // this.bsModalRef.hide();
        this.onClose(true);
        this._configs.success(this.translate.instant('LOAD.SUCCESS.FEEDBACK_SUBMITTED', { order: this.item.order_number}));
        this.dataLoaded = true;
        this.dataLoading = false;
      }, (err) => {
        this.bsModalRef.hide();
        this.dataLoaded = true;
        this.dataLoading = false;
        this._configs.serverMessageHandler(err);
      });
    } else {
      setTimeout(() => {
        if (document.querySelector('.invalid-tooltip')) {
          document.querySelector('.invalid-tooltip').scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
}
