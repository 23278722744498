(function (window) {
    window.__env = window.__env || {};

    window.__env.razorPayKey = "rzp_test_k65NGyP73xDOu3"; // test key

    // Rydot Creds
    window.__env.apiUrl = 'https://5k35hv41v6.execute-api.ap-south-1.amazonaws.com/dev';
    window.__env.uploadsUrl = 'https://5k35hv41v6.execute-api.ap-south-1.amazonaws.com/dev/common/';

    // Arhamvijja Creds
    // window.__env.apiUrl = 'https://s1yq375y2a.execute-api.ap-south-1.amazonaws.com/dev';
    // window.__env.uploadsUrl = 'https://s1yq375y2a.execute-api.ap-south-1.amazonaws.com/dev/common/';
    
    // window.__env.apiUrl = 'http://localhost:3000';
    // window.__env.uploadsUrl = 'http://localhost:3000/common/';

    // Whether or not to enable debug mode
    // Setting this to false will disable console output
    window.__env.enableDebug = true;
}(this));