import { Injectable } from '@angular/core';
import { AppConfigsService } from '../app-configs/app-configs.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiHandlerService {

  API_URLS;
  API_URLS1;

  constructor(
    public _configs: AppConfigsService,
    private http: HttpClient) {
    this.API_URLS = this._configs.env.apiUrl;
    this.API_URLS1 = this._configs.env.uploadsUrl;
  }

  callCreateQuery(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/feedback', params);
  }

  callCreate(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/create', params);
  }

  callCommentsQuery(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/comment', params);
  }

  callGetQuery(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/get/' + itemId, params);
  }

  callGetOrderQuery(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/' + itemId, params);
  }

  callGetListQuery(params, type) {
    return this.http.post(this.API_URLS + '/account/orders/' + type, params);
  }

  callUpdateQuery(params, type, itemId) {
    return this.http.put(this.API_URLS + '/' + type + '/' + itemId, params);
  }

  getProfile() {
    return this.http.post(this.API_URLS + '/account/profile/get', {});
  }

  updateProfile(params) {
    return this.http.post(this.API_URLS + '/account/profile/update', params);
  }

  uploadPhoto(params: any, url) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.request(new HttpRequest('POST',
      this.API_URLS + url, params, {
      headers: headers,
      reportProgress: true
    })
    );
  }

  imageUplod(params, type) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.request(new HttpRequest('POST',
      this.API_URLS + '/account/profile/' + type, params, {
      headers: headers,
      reportProgress: true
    })
    );
    // return this.http.post(this.API_URLS + '/account/profile' + type, params);
  }

  callGetQueryWithoutId(params = {}, type) {
    return this.http.post(this.API_URLS + '/' + type + '/' + 'list', params);
  }

  changePassword(params) {
    return this.http.post(this.API_URLS + '/change-password', params);
  }

  getStateCity(params) {
    return this.http.post(this.API_URLS + '/state/get', params);
  }

  validatePhone(params) {
    return this.http.post(this.API_URLS + '/account/profile/update-request', params);
  }

  updateOtp(params) {
    return this.http.post(this.API_URLS + '/account/profile/submit-otp', params);
  }

  callCategoryList(params, type) {
    return this.http.post(this.API_URLS + '/public/' + type, params);
  }

  callCategoryListWithId(params, type, itemId) {
    return this.http.post(this.API_URLS + '/public/' + type + '/' + itemId + '/items', params);
  }

  cartDelete(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/delete/' + itemId, params);
  }

  savedLocation(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/create', params);
  }

  editLocation(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/update/' + itemId, params);
  }

  listLocation(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/list', params);
  }

  // addToFavourites(params, type, itemId) {
  //   return this.http.post(this.API_URLS + '/account/' + type + '/set-favorites/' + itemId, params);
  // }

  callFavoutitesList(params, type) {
    return this.http.post(this.API_URLS + '/account/' + type + '/list-favorites', params);
  }

  callPincodeQuery(params, type) {
    return this.http.post(this.API_URLS + '/account/profile/' + type, params);
  }

  createOrders(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/create', params);
  }

  updateOrders(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/update/' + itemId, params);
  }

  deleteLocation(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/delete/' + itemId, params);
  }

  setDefaultAddress(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/set-default/' + itemId, params);
  }

  addToFavourites(params, type, itemId) {
    return this.http.post(this.API_URLS + '/account/' + type + '/set-favorites/' + itemId, params);

  }
  addToFavouriteBulk(params, type) {
    return this.http.post(this.API_URLS + '/account/' + type + '/bulk-favorites', params);
  }

  // getCategories(params) {
  //   return this.http.post(this.API_URLS + '/common/categories', params);
  // }

  callMultipleListQuery(params, type1, type2) {
    return this.http.post(this.API_URLS + '/' + type1 + '/orders/' + type2, params);
  }

  fetchUploadUrl(params = {}) {
    return this.http.post(this.API_URLS1 + 'uploads', params);
  }

  fetchTempUrl(params = {}) {
    return this.http.post(this.API_URLS1 + 'get-temp-url', params);
  }

  uploadImages(url, body, type) {
    return this.http.put(url, body, {
      headers: {
        'Content-Type': type
      }
    });
  }

  getRelatedImages(params, type) {
    return this.http.post(this.API_URLS + '/public/' + type + '/related-items', params);
  }

  callPayement(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/' + itemId, params);
  }

  cartItemDelete(params, type1, type2, itemId) {
    return this.http.post(this.API_URLS + '/' + type1 + '/delete/' + type2 + '/' + itemId, params);
  }

  callGetNotificationList(params, type1, type2) {
    // return this.http.post(this.API_URLS + '/' + type1 + '/' + type2 + '/', params);
    return this.http.post(`${this.API_URLS}/${type1}/${type2}/list`, params);
  }

  callGetNotification(params, type1, type2, id) {
    // return this.http.post(this.API_URLS + '/' + type1 + '/' + type2 + '/', params);
    return this.http.post(`${this.API_URLS}/${type1}/${type2}/read/${id}`, params);

  }

  callOrderRescheduleQuery(params, type, itemId) {
    // return this.http.post(this.API_URLS + '/' + type + '/reschedule-cancel/' + itemId, params);
    return this.http.post(`${this.API_URLS}/${type}/reschedule-cancel/${itemId}`, params);
  }

  callExportQuery(type: string, params) {
    return this.http.post(this.API_URLS + type, params);
  }

  calOrderSummery(params, type, itemId) {
    return this.http.post(this.API_URLS + '/' + type + '/summary/' + itemId, params);
  }

  callBulkNotification(params) {
    return this.http.post(this.API_URLS + '/account/notifications/bulk-read', params);
  }

  callProfileGenerate() {
    return this.http.post(this.API_URLS + '/account/generate/sharable-link', {});
  }

  callGuruList(type, params) {
    return this.http.post(this.API_URLS + '/public/' + type + '/related-mentors', params);
  }

  callPaymentFlagQuery(type, params, token) {
    return this.http.post(this.API_URLS + '/account/subscription/' + type, params, {
      headers: {
        'x-access-token': token
      }
    });
  }

  callGuruSlots(type, params) {
    return this.http.post(this.API_URLS + '/public/sub-categories/mentor-slots/' + type, params);
  }

  callCalGuruSlots(params) {
    return this.http.post(this.API_URLS + '/public/sub-categories/date-wise-mentors', params);
  }

  callGuruDetails(guruID, params) {
    return this.http.post(this.API_URLS + '/public/guru-detail/' + guruID, params);
  }

  callGetQueryList(params, type) {
    return this.http.post(this.API_URLS + '/accounts/' + type, params);
  }

  callSearchQuery(params, type) {
    return this.http.post(this.API_URLS + '/' + type + '/search', params);
  }

  callChangePw(params) {
    return this.http.post(this.API_URLS + '/account/profile/change-password', params);
  }
}
