import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
  selector: '[appEndDateValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EndDateValidationDirective, multi: true }]
})
export class EndDateValidationDirective {
  public StartDateRequired: Boolean;
  @Input('appEndDateValidation') shouldbeless: any;
  constructor() {
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    const startDate = new Date(this.shouldbeless);
    const endDate = new Date(control.value);
    if (control.value == null) {
      return null;
    } else {
      return (endDate < startDate) ? { StartDateIsMore: true } : null;
    }
  }
}
