import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-modal-time-display',
  templateUrl: './modal-time-display.component.html',
  styleUrls: ['./modal-time-display.component.scss']
})
export class ModalTimeDisplayComponent implements OnInit {
  onClose: any;
  confirmMessage: any;
  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    // this.confirmMessage 
  }

}
