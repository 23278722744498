import { AfterViewInit, ChangeDetectorRef, Component, Inject, NgZone, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AppConfigsService } from 'src/app/shared/services/app-configs/app-configs.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider, FacebookLoginProvider, SocialUser, SocialAuthService } from 'angularx-social-login';
import { ApiHandlerService } from '../../services/api-handler/api-handler.service';
import { ModalCartDeleteComponent } from '../modal-cart-delete/modal-cart-delete.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe, CurrencyPipe]

})
export class HeaderComponent implements OnInit {
  razorPayOptions;
  signinData;
  mobileValidationMessage;
  html;
  errorMessage: string;
  notificationCount = 0;
  notificationFlag = false;
  innerNotificationFlag = false;
  getitemsArray: any;
  items = [];
  deleteCartDisabled = false;
  deleteFavouriteDisabled = false;
  otpOptionShow = false;
  otpOptionButton = true;
  // clickOutSideCounter = 0;
  // clickCartCounter = 0;
  cart = [];
  currentCategoryName = '';
  favourite = [];
  isNotified = false;
  notificationsList = [];
  bsModalRef: BsModalRef;
  modalRef2: BsModalRef;
  subCategories = [];
  isFlag = false;
  isDropDownShow = false;
  isSignin = true;
  accessToken;
  isSignup = false;
  signupForm: FormGroup;
  formSubmitting = false;
  formSubmitAttempt;
  fieldTextTypePassword;
  searchInputselect = false;
  transPassword;
  transAcceptTermsAndCondition;
  transSuccess; transLastName;
  transEmainAndPhoneNum;
  transRefferralCode;
  queryParams;
  searchInput = '';
  transOTP: '';
  transName;
  isApply = true;
  cartLength = 0;
  isCartPopupShow = false;
  isFavouritePopupShow = false;

  formData = {
    username: null,
    isAccept: null,
    password: null,
    first_name: '',
    last_name: '',
    referral_code: '',
    OTP: ''
  };

  response;
  socialusers: SocialUser;
  firstChar: any;
  totalCount;
  favouriteItem;
  checkOut = false;
  parseNote: any;
  token: any;

  constructor(
    private _auth: AuthService,
    public _configs: AppConfigsService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private apihandler: ApiHandlerService,
    private socialAuthService: SocialAuthService,
    public apiHandler: ApiHandlerService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cp: CurrencyPipe,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private _document: HTMLDocument) {

    this._configs.favouritePopupOpen(false);
    this._configs.sharecategoryNull.subscribe((data) => {
      this.searchInputselect = data;
    });

    this._configs.shareCategoryNamevalue.subscribe((data) => {
      this.currentCategoryName = data;
    });

    this._configs.currentSelectedFavourArray.subscribe((res: any) => {
      const localStorageFavourite = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
      const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
      if (accessToken) {
        this.getFavourites();
      } else {
        this.favourite = [];
      }
      if (res && res.length > 0) {
        this.favourite = res;
      } else {
        this.favourite = localStorageFavourite;
      }

    });

    this._configs.currentSelectedItem.subscribe((res: any) => {
      // console.log(res);
      this.cart = res;
    });

    this.getCategory();
    this.getToken();
    this.getItems();
    this._configs.dropDownShowHide(false);
    this.transEmainAndPhoneNum = this.translate.instant('LOAD.FORM.EMAIL_AND_PHONE_NUMBER');
    this.transPassword = this.translate.instant('LOAD.FORM.PASSWORD');
    this.transSuccess = translate.instant('LOAD.SUCCESS.SIGNUP');
    this.transAcceptTermsAndCondition = translate.instant('LOAD.ERROR.TERM_CONDITION_REQUIRED');
    this.transName = translate.instant('LOAD.FORM.FIRST_NAME');
    this.transRefferralCode = translate.instant('LOAD.FORM.REFERRAL_CODE');
    this.transLastName = translate.instant('LOAD.FORM.LAST_NAME');
    this.transOTP = this.translate.instant('LOAD.FORM.OTP');
    // this.groups = [
    //   {
    //     id: 100,
    //     name: 'Austria',
    //     children: [
    //         {id: 54, name: 'Vienna'},
    //     ],
    // },
    // {
    //     id: 200,
    //     name: 'Belgium',
    //     children: [
    //         {id: 2, name: 'Antwerp'},
    //         {id: 9, name: 'Brussels'},
    //     ],
    // },
    // {
    //     id: 300,
    //     name: 'Bulgaria',
    //     children: [
    //         {id: 48, name: 'Sofia'},
    //     ],
    // },
    // ]

  }



  ngOnInit() {

    this.getNotificationList();

    this.firstChar = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.currentUser));

    this._configs.shareGetPrifile.subscribe((data) => {
      if (data) {
        this.firstChar = data;
      }
    });

    this._configs.changeCartLength();
    this._configs.currentCartItem.subscribe((data: any) => {
      this.cartLength = data;
    });

    this.createForm();

    this._configs.shareLoginPopupShowHide.subscribe((data) => {
      this.showPopupForceFully(data);
    });

    this._configs.shareCartOpen.subscribe((data: any) => {

      if (data) {
        this.cartOpenForceFully(data);
      }
    });


    this._configs.shareFavouriteOpen.subscribe((data: any) => {
      if (data) {
        this.favouriteOpenForceFully(data);
      }
    });
  }

  setCartLogin() {
    this.getToken();
    this.firstChar = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.currentUser));

    this._configs.shareGetPrifile.subscribe((data) => {
      if (data) {
        this.firstChar = data;
      }
    });
  }

  showPopupForceFully(value) {
    this.isDropDownShow = value;
    this.setAndRemoveAttribute(this.isDropDownShow);
  }

  dropDownShow() {
    this.isDropDownShow = !this.isDropDownShow;

    if (this.isDropDownShow === true) {
      this.isCartPopupShow = false;
      this.isNotified = false;
    }

    this.setAndRemoveAttribute(this.isDropDownShow);
    this._configs.dropDownShowHide(this.isDropDownShow);
  }

  setAndRemoveAttribute(value) {
    if (value) {
      this._document.getElementById('overlayId').classList.add('bg-overlay');
    } else {
      this._document.getElementById('overlayId').classList.remove('bg-overlay');
    }
  }

  showSignin() {
    this.isSignin = true;
    this.isSignup = false;
    this.otpOptionShow = false;
    this.createForm();
  }

  showSignup() {
    this.isSignin = false;
    this.isSignup = true;
    this.createForm();
  }

  createForm() {
    this.signupForm = new FormGroup({
      // first_name: new FormControl(this.formData.first_name, [Validators.required]),
      // last_name: new FormControl(this.formData.last_name, [Validators.required]),
      username: new FormControl(this.formData.username, [Validators.required, Validators.pattern(this._configs.patterns.tempEmailOrPhone)]),
      password: new FormControl(this.formData.password,
        [Validators.required, Validators.pattern(this._configs.patterns.password)]),
      // code: new FormControl(this.formData.OTP, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
      referral_code: new FormControl(this.formData.referral_code)
    });
  }

  doSignUp() {
    // this.errorMessage = '';
    // this.mobileValidationMessage = '';
    this.formSubmitAttempt = true;
    this.checkUserName();
    
    if (this.isApply) {
      // if (resend || !this.otpOptionShow) {
      //   this.queryParams.code = '';
      // }
      if (this.signupForm.valid) {
        this.signupForm.get('username').value.trim();
        this.signupForm.get('password').value.trim();
        this.signupForm.get('referral_code').value.trim();
        // if (this.otpOptionShow && !this.signupForm.get('code').valid && !resend) {
        //   return false;
        // }
        this.formSubmitting = true;
        this._auth.signup(this.queryParams, this._configs.apiKeys.signup).subscribe((data: any) => {
          this.formSubmitting = false;
          this._configs.success(this.translate.instant('LOAD.SUCCESS.SIGNUP_SUCCESS'));
          this.showSignin();


          // if (this.signupForm.get('username').valid && this.signupForm.get('code').valid && !resend) {
          //   this._configs.success(this.translate.instant('LOAD.SUCCESS.SIGNUP_SUCCESS'));
          // } else {
          //   this.formSubmitAttempt = false;
          //   // this._configs.success(this.translate.instant('LOAD.SUCCESS.OTP_SUCCESS'));
          // }

          // if (this.queryParams.code) {
          //   // this.redirectSignin();
          //   localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.accessToken, data.token);
          //   localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction,
          //     JSON.stringify(this._configs.idleTime));
          //   if (data.item) {
          //     localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.currentUser,
          //       JSON.stringify({
          //         first_name: data.item.first_name,
          //         email: data.item.email,
          //         last_name: data.item.last_name,
          //         phone: data.item.phone
          //       })
          //     );
          //   }
          //   // this._configs.success(this.translate.instant('LOAD.SUCCESS.SIGNUP_SUCCESS'));
          //   // this.isSignin = true;
          //   // this.isSignup = false;
          //   this.storeCartItems();
          //   if (this.checkOut === true) {
          //     this.router.navigate([this._configs.routeUrl.my_cart]);
          //     this._configs.dropDownShowHide(false);
          //     this.isFlag = true;
          //     // this.getAccessToken.emit(this.isFlag);
          //   } else {
          //     this.router.navigate([this._configs.routeUrl.dashboard]);
          //   }
          //   this.otpOptionShow = false;
          //   // this.router.navigate([this._configs.routeUrl.dashboard]);
          // } else {
          //   this.otpOptionShow = true;
          // }

        }, (err) => {
          this.formSubmitting = false;
          // Setup localization server massage
          // this.errorMessage = this._configs.serverMessageForAuth(err);
          this._configs.serverMessageHandler(err);
        });
      } else {
        this.formSubmitAttempt = true;
      }
    }
  }

  isFieldValid(field: string) {
    return !this.signupForm.get(field).valid && (this.signupForm.get(field).touched ||
      (this.signupForm.get(field).untouched && this.formSubmitAttempt));
  }

  toggleFieldTextTypePassword() {
    this.fieldTextTypePassword = !this.fieldTextTypePassword;
  }

  redirectSignin() {
    this.isSignin = true;
    this.isSignup = false;
  }

  socialSignin(socialProvider) {
    let options = true;
    let socialPlatformProvider;
    if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      options = true;
    } else if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      options = false;
    }

    if (options) {
      this.socialAuthService.signIn(socialPlatformProvider, {
        prompt: 'select_account'
      }).then(socialusers => {

        this.Savesresponse(socialusers);
      }).catch((error) => {
        // this._configs.serverMessageHandler(error);
        // this._configs.error('Unable to login');
      });
    } else {
      this.socialAuthService.signIn(socialPlatformProvider).then(socialusers => {
        this.Savesresponse(socialusers);
      }).catch((error) => {
        // this._configs.serverMessageHandler(error);
        // this._configs.error('Unable to login');
      });
    }


  }

  Savesresponse(socialusers: SocialUser) {
    this._auth.socialSignin(socialusers, this._configs.apiKeys.social_login).subscribe((res: any) => {
      // this.socialusers = res;
      // this.response = res.userDetail;
      // localStorage.setItem(this._configs.lsPrefix + 'socialusers', JSON.stringify(this.socialusers));

      this.isPyamentDoneOrNot(res);

      // localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.accessToken, res.token);
      // localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction,
      //   JSON.stringify(this._configs.idleTime));

      // if (res.item) {
      //   localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.currentUser,
      //     JSON.stringify({
      //       first_name: res.item.first_name,
      //       email: res.item.email,
      //       last_name: res.item.last_name,
      //       phone: res.item.phone
      //     })
      //   );
      // }
      // this._configs.success(this.translate.instant('LOAD.SUCCESS.LOGIN_SUCCESS'));
      // this.storeCartItems();

      // if (this.checkOut === true) {
      //   this.router.navigate([this._configs.routeUrl.my_cart]);
      //   this._configs.dropDownShowHide(false);
      //   this.isFlag = true;
      //   // this.getAccessToken.emit(this.flag);
      // }

      // if (this.checkOut === false) {
      //   this.router.navigate([this._configs.routeUrl.dashboard]);
      // }
      // this.router.navigate([this._configs.routeUrl.dashboard]);
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  signOut() {
    this._auth.signOut({}, this._configs.apiKeys.signout).subscribe((data: any) => {
      localStorage.clear();
      const appConfig: any = {
        emailId: this._configs.nativeWindow.emailId,
        phoneNumber: this._configs.nativeWindow.phoneNumber,
        servingArea: this._configs.nativeWindow.servingArea,
        googleMapKey: this._configs.nativeWindow.googleMapAPIKey,
      };
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.appConfigs, JSON.stringify(appConfig));
      this.notificationsList = [];
      const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
      this._configs.addedItem(result);
      this.checkOut = false;
      this._configs.redirectCartFun(false);
      this._configs.addFavouriteInArray([]);
      // this._configs.addFavouriteInObject({});
      this._configs.changeCartLength();
      // this._configs.setTempValue('all');
      localStorage.removeItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray);
      this.notificationsList = [];
      this.notificationCount = 0;
      this.getToken();
      this._configs.success(this.translate.instant('LOAD.SUCCESS.SIGNOUT_SUCCESS'));
      this.closeAllPopup();
      this.router.navigate([this._configs.routeUrl.index]);
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  checkUserName() {
    const value = this.signupForm.get('username').value;
    if (this._configs.patterns.phoneNumberPattern.test(value)) {
      this.queryParams = {
        phone: value,
        password: this.signupForm.get('password').value,
        referral_code: this.signupForm.get('referral_code').value,
      };
      this.isApply = true;
    } else if (this._configs.patterns.tempEmail.test(value)) {
      this.queryParams = {
        email: value,
        password: this.signupForm.get('password').value,
        referral_code: this.signupForm.get('referral_code').value,
      };
      this.isApply = true;
    } else {
      // this.clearErrorMessage();
      if (this._configs.patterns.digitOnlyPattern.test(value)) {
        if (value.length > 10) {
          this.mobileValidationMessage = 'Mobile number length must be 10 digit only';
          this.isApply = false;
        }
      } else {
        if (value) {
          this.mobileValidationMessage = 'Email or Mobile Number is invalid. Enter correct Email or Mobile Number';
          this.isApply = false;
        }
      }
    }
  }

  getToken() {
    this.accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    if (this.accessToken) {
      this.isFlag = true;
    } else {
      this.isFlag = false;
      if (this.route.snapshot.queryParams.login) {
        setTimeout(() => {
          this.dropDownShow();
        }, 500);
      }
    }
    // if(this.checkOut === true) {
    //   this.isFlag = true;
    // }
  }

  getCategory() {
    this.apiHandler.callCategoryList({
      sortBy: 'sorting_order',
      sort: 'asc'
    }, this._configs.apiKeys.sub_categories).subscribe((data: any) => {
      let items;
      let uniqueChars = [];
      let uniqueName = [];
      if (data.list) {
        items = data.list.reduce((res, req) => {
          if (!uniqueChars.includes(req.parent.id)) {
            uniqueChars.push(req.parent.id);
            uniqueName.push({ id: req.parent.id, name: req.parent.name });
          }
          res[req.parent.id] = (res[req.parent.id] || []).concat(req);
          return res;
        }, {});
      };

      uniqueName.map(el => {
        this.subCategories.push({
          id: el.id,
          name: el.name,
          children: items[el.id].map(item => {
            return {
              id: item.slug,
              name: item.name,
              slug: item.slug
            }
          }),
        });
      });
      // const arr = {
      //   id:'all',
      //   name:'All Categories'
      // }
      // this.subCategories = [].concat(arr,...this.subCategories)
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  redirect(event) {
    let eve = event.target && event.target.value ? event.target.value : event;
    this.searchInputselect = false;
    if (eve === this.searchInput || eve === '') {
      this._configs.cartPopupOpen(false);
      this._configs.favouritePopupOpen(false);
      this.searchInputselect = true;
      this.router.navigate([this._configs.routeUrl.index]);
      return false;
    } else {
      this._configs.cartPopupOpen(false);
      this._configs.favouritePopupOpen(false);
      this.router.navigate([this._configs.routeUrl.category, eve]);
      return false;
    }
  }

  showCart() {
    if (this.cartLength < 1) {
      return 0;
    } else {
      this.isCartPopupShow = !this.isCartPopupShow;

      if (this.isCartPopupShow === true) {
        // window.scroll(0, 0);
        this.isDropDownShow = false;
        this.isNotified = false;
      }

      this.setAndRemoveAttribute(this.isCartPopupShow);
    }
  }

  showFavourite() {
    const favObj = JSON.stringify(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
    if (favObj && this.favourite.length < 1) {
      return 0;
    } else {
      this.isFavouritePopupShow = !this.isFavouritePopupShow;

      if (this.isFavouritePopupShow === true) {
        this.isDropDownShow = false;
        this.isNotified = false;
        this.isCartPopupShow = false;
      }

      this.setAndRemoveAttribute(this.isFavouritePopupShow);
    }
  }

  redirctToCart(id = null) {

    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    this.isCartPopupShow = false;
    this.setAndRemoveAttribute(this.isCartPopupShow);
    if (accessToken) {
      // this.closeAllPopup();
      // console.log('cart');
      // console.log(id);
      this._configs.isCheckOut(true);
      this.router.navigate([this._configs.routeUrl.my_cart], { queryParams: { id: id } });
    } else {
      // console.log('out');
      this._configs.success(this.translate.instant('LOAD.MESSAGE.CART_SIGNUP'));
      this._configs.redirectCartFun(true);
      this._configs.dropDownShowHide(true);
      this.checkOut = true;
      // console.log('test', this.isFlag);
      this.getToken();
    }

  }

  redirctTOFavourite() {
    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    this.isFavouritePopupShow = false;
    this.setAndRemoveAttribute(this.isFavouritePopupShow);
    if (accessToken) {
      // this.closeAllPopup();
      this.router.navigate([this._configs.routeUrl.favourites]);
    } else {
      const favouriteItemArray = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
      this._configs.addFavouriteInArray(favouriteItemArray);
      this.closeAllPopup();
      this.router.navigate([this._configs.routeUrl.fav]);
      // this._configs.redirectCartFun(true);
      // this._configs.dropDownShowHide(true);
    }
  }

  openDelete(item: any) {
    this.deleteCartDisabled = true;
    this.bsModalRef = this.modalService.show(ModalCartDeleteComponent, {
      class: 'modal-md',
      initialState: {
        confirmMessage: 'LOAD.CONTENT.DELETE_CONFIRM',
        data: { title: item.name }
      },
      backdrop: true,
      ignoreBackdropClick: false
    });
    this.bsModalRef.content.onClose = (flagConfirm) => {
      if (flagConfirm) {
        this.deleteCartItem(item);
      } else {
        this.deleteCartDisabled = false;

        const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
        if (result.length === 0) {
          this.isCartPopupShow = false;
          this.setAndRemoveAttribute(this.isCartPopupShow);
          this.router.navigate([this._configs.routeUrl.index]);
        } else {
          this.isCartPopupShow = true;
          this.setAndRemoveAttribute(this.isCartPopupShow);
        }

      }
      this.bsModalRef.hide();
    };
  }

  openDeleteFavourite(item: any) {
    this.deleteFavouriteDisabled = true;

    this.bsModalRef = this.modalService.show(ModalCartDeleteComponent, {
      class: 'modal-md',
      initialState: {
        confirmMessage: 'LOAD.CONTENT.DELETE_CONFIRM',
        data: { title: item.item.name }
      },
      backdrop: true,
      ignoreBackdropClick: false
    });
    this.bsModalRef.content.onClose = (flagConfirm) => {
      if (flagConfirm) {
        this.deleteFavouriteItem(item);
      } else {
        this.deleteFavouriteDisabled = false;

        const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
        if (result.length === 0) {
          this.isFavouritePopupShow = false;
          this.setAndRemoveAttribute(this.isFavouritePopupShow);
          this.router.navigate([this._configs.routeUrl.index]);
        } else {
          this.isFavouritePopupShow = true;
          this.setAndRemoveAttribute(this.isFavouritePopupShow);
        }

      }
      this.bsModalRef.hide();
    };
  }

  deleteCartItem(item) {
    // this.deleteCartDisabled = true;
    // console.log(item);
    const id = item.id;
    const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    const cart = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItems));
    if (cart && cart.id && cart !== null) {
      this.apiHandler.cartItemDelete(
        { item_id: id }, this._configs.apiKeys.orders, this._configs.apiKeys.cart_item, cart.id).subscribe((data: any) => {
          // console.log('called-1');
          result.forEach((element, i) => {
            if (id === element.id) {
              result.splice(i, 1);
            }
            // i++;
          });

          cart.items = result;
          localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(result));

          if (cart && cart.id && cart !== null) {
            localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cart));
          }
          this._configs.changeCartLength();
          this._configs.addedItem(result);
          this.getItems();
          // this.calculateCostPerItem();
          this._configs.success(this.translate.instant('LOAD.SUCCESS.DELETE_SUCCESS', { value: item.name }));
          // this._configs.success(this.translate.instant('LOAD.SUCCESS.DELETE_SUCCESS'));

          if (result.length === 0) {
            this.isCartPopupShow = false;
            this.setAndRemoveAttribute(this.isCartPopupShow);
            this.router.navigate([this._configs.routeUrl.index]);
          } else {
            // console.log(result);
            if (result && result.length > 0) {
              let cartId = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.cartItemId));
              // console.log(cartId);
              let checkItemArray = result.filter((ele) => {
                if (cartId && (ele.id === cartId)) {
                  return ele;
                }
              });
              // console.log(checkItemArray);
              if (cartId && checkItemArray && checkItemArray.length === 0) {
                this.router.navigate([this._configs.routeUrl.index]);
              }
            }
            this.isCartPopupShow = true;
            this.setAndRemoveAttribute(this.isCartPopupShow);
          }

          // if (item.is_package === 1) {
          //   this._configs.isPackgeCheck(item.is_package);
          // }
          this.deleteCartDisabled = false;
        }, (err) => {
          this.deleteCartDisabled = true;
          this._configs.serverMessageHandler(err);
        });

    } else {
      // console.log('called-2');
      result.forEach((element, i) => {
        if (id === element.id) {
          result.splice(i, 1);
          // console.log(element);
          this._configs.success(this.translate.instant('LOAD.SUCCESS.DELETE_SUCCESS', { value: element.name }));
          // this._configs.success(this.translate.instant('LOAD.SUCCESS.DELETE_SUCCESS'));
          this.deleteCartDisabled = false;
        }
        // i++;
      });

      // if (item.is_package === 1) {
      //   this._configs.isPackgeCheck(item.is_package);
      // }
    }

    if (result.length === 0) {
      localStorage.removeItem(this._configs.lsPrefix + this._configs.localStorage.items);
      this._configs.changeCartLength();
      this._configs.addedItem(result);
      this.isCartPopupShow = false;
      this.setAndRemoveAttribute(this.isCartPopupShow);
      this.router.navigate([this._configs.routeUrl.index]);
    } else {
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(result));

      if (cart && cart.id && cart !== null) {
        localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cart));
      }
      this._configs.changeCartLength();
      this._configs.addedItem(result);
      this.getItems();
      this.isCartPopupShow = true;
      this.setAndRemoveAttribute(this.isCartPopupShow);
    }
  }

  deleteFavouriteItem(item) {
    const id = item.item.id;
    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    if (accessToken) {
      this.removeToFavourites(item);
    }
    const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray));
    const favouriteObj = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItems));
    // this.favourite = result;
    let index = -1;
    let i = -1;
    if (result && result !== null && result.length > 0) {
      result.forEach((element, i) => {
        if (id === element.item.id) {
          index = i;
        }
      });
    }
    if (favouriteObj && favouriteObj !== null && favouriteObj.length > 0) {
      favouriteObj.forEach((element, ind) => {
        if (id === element.item_id) {
          i = ind;
        }
      });
    }
    if (index > -1) {
      result.splice(index, 1);
    }
    if (i > -1) {
      favouriteObj.splice(i, 1);
    }
    if (result && result.length === 0) {
      this.isFavouritePopupShow = false;
      this.setAndRemoveAttribute(this.isFavouritePopupShow);
      this.router.navigate([this._configs.routeUrl.index]);
    } else {
      this.isFavouritePopupShow = true;
      this.setAndRemoveAttribute(this.isFavouritePopupShow);
    }
    this._configs.addFavouriteInArray(result);
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(result));
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItems, JSON.stringify(favouriteObj));

  }

  getItems() {
    this.getitemsArray = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    this.cart = this.getitemsArray;
  }

  getNotificationList() {
    const token = this._configs.checkTokenValidity();
    if (token) {
      this.apiHandler.callGetNotificationList(
        {}, this._configs.apiKeys.account, this._configs.apiKeys.notifications
      ).subscribe((data: any) => {
        this.notificationsList = data.list;
        this.notificationsList.forEach((el) => {
          el.request_note = JSON.parse(el.request_note);
          // console.log(el.request_note);
        });
        // console.log(this.notificationsList);
        this.notificationCount = data.count;
        // this.totalCount = data.count;
      }, (err) => {
        this._configs.serverMessageHandler(err);
      });
    }
  }

  deleteNotification(item, index) {
    this.apiHandler.callGetNotification(
      { permanent: true }, this._configs.apiKeys.account, this._configs.apiKeys.notifications, item.id
    ).subscribe((data: any) => {
      this.notificationsList.splice(index, 1);
      if (this.notificationsList.length === 0) {
        this.isNotified = false;
        this.setAndRemoveAttribute(this.isNotified);
      }
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  searchQuery(submitData) {
    // this.closeAllPopup();
    if (submitData.valid) {
      if (this.searchInput !== '') {
        this.router.navigate([this._configs.routeUrl.search, this.searchInput.trim()]);
      }
      submitData.form.reset();
    }
  }

  openNotification() {
    // if (this.notificationsList.length < 1) {
    //   return 0;
    // } else {
    this.isNotified = !this.isNotified;
    if (this.isNotified === true) {
      this.callBulkNotification();
      this.isCartPopupShow = false;
      this.isDropDownShow = false;
    }
    this.setAndRemoveAttribute(this.isNotified);
    // }
  }

  callBulkNotification() {
    const tempIdsArray = this.notificationsList.filter(el => !el.is_read).map(ele => ele.id);
    if (tempIdsArray.length > 0) {
      this.notificationFlag = true;
      this.apiHandler.callBulkNotification({ ids: tempIdsArray }).subscribe((data) => {
      }, (err) => {
        this._configs.serverMessageForAuth(err);
      });
    }
  }

  closeAllPopup() {
    this.notificationHideShow();
    this.deleteCartDisabled = false;
    this.deleteFavouriteDisabled = false;
    this.isCartPopupShow = false;
    this.isDropDownShow = false;
    this.isNotified = false;
    this.isFavouritePopupShow = false;
    this._configs.dropDownShowHide(false);
    this._configs.cartPopupOpen(false);
    this._configs.favouritePopupOpen(false);
  }

  redirectOrder(template: TemplateRef<any>, item) {
    this.parseNote = item;
    if (item.event === 'quotation' || item.event === 'quoted' || item.event === 're-quote') {
      if (this.parseNote && this.parseNote.request_note.admin_note) {
        this.parseNote.request_note.total_cost = (
          Number(this.parseNote.request_note.estimated_sub_total) + Number(this.parseNote.request_note.studio_charges) +
          Number(this.parseNote.request_note.models_charges) + Number(this.parseNote.request_note.stylist_charges)
          + Number(this.parseNote.request_note.other_charges) - Number(this.parseNote.request_note.discount));
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.admin_note);
        this.html = this.parseNote.request_note.admin_note.replace(
          '{estimated_sub_total}', this.cp.transform(this.parseNote.request_note.estimated_sub_total, 'INR'))
          .replace('{studio_charges}', this.cp.transform(this.parseNote.request_note.studio_charges, 'INR') || '0')
          .replace('{models_charges}', this.cp.transform(this.parseNote.request_note.models_charges, 'INR') || '0')
          .replace('{stylist_charges}', this.cp.transform(this.parseNote.request_note.stylist_charges, 'INR') || '0')
          .replace('{other_charges}', this.cp.transform(this.parseNote.request_note.other_charges, 'INR') || '0')
          .replace('{discount}', this.cp.transform(this.parseNote.request_note.discount, 'INR') || '0')
          .replace('{total_cost}', this.cp.transform(this.parseNote.request_note.total_cost, 'INR') || '0')
          .replace('{taxes}', this.parseNote.request_note.taxes)
          .replace('{inclusive_taxes}', this.cp.transform(this.parseNote.request_note.quoted_price, 'INR'))
          .replace('{admin_approved_status}', (
            this.datePipe.transform(this.parseNote.request_note.admin_approved_shoot_date, 'dd/MM/yyyy h:mm a')))
          .replace('{link}', `<a class='text-primary' target="_blank"
         href="${this.parseNote.request_note.razorpay_url}">${this.parseNote.request_note.razorpay_url}</a>`);
      }
    }

    if (item.event === 'approve-rescheduled') {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.txt_reschedule);
    }

    if (item.event === 'canceled') {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.txt_cancelled);
    }

    if (item.event === 'partial-payment' || item.event === 'full-payment' || item.event === 'manual-payment') {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.paymentData.txt_payment);
    }

    if (item.event === 'image-view' || item.event === 'image-download') {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.imageData);
    }
    if (item.event === 'artist-assigned') {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.parseNote.request_note.artistData);
    }
    this.modalRef2 = this.modalService.show(template, {
      class: 'modal-lg requote modal-dialog-scrollable',
      backdrop: true,
      ignoreBackdropClick: true
    });

    this.innerNotificationFlag = true;
    this.isNotified = false;
    this.isDropDownShow = false;
    this._configs.dropDownShowHide(false);
    // this._configs.dropDownShowHide(!this.isNotified);
    // console.log(item.order.order_status);
    // if (item.order.order_status === 'in_progress' || item.order.order_status === 'completed') {
    //   this.router.navigate([this._configs.routeUrl.orders]);
    // } else {
    //   this.router.navigate([this._configs.routeUrl.quotations]);
    // }

    // this.isNotified = false;
    // this.setAndRemoveAttribute(this.isNotified);
  }

  notificationHideShow() {
    if (this.isNotified && (this.notificationFlag || this.innerNotificationFlag)) {
      this.notificationFlag = false;
      this.innerNotificationFlag = false;
      this.notificationCount = 0;
      this.getNotificationList();
    }
  }

  close() {
    this.modalRef2.hide();
    setTimeout(() => {
      this.isDropDownShow = false;
      this._configs.dropDownShowHide(true);
      this.isNotified = true;
    }, 500);
  }

  storeCartItems() {
    this.checkLocalStorage();
    if (this.items.length > 0) {
      this._configs.addedItem(this.items);
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(this.items));
      this._configs.changeCartLength();
      // delete data['items'];
      const cartItems: any = {};
      cartItems.items = this.items;
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(cartItems));
      this._configs.changeCartLength();
    }
  }

  checkLocalStorage() {
    const result = JSON.parse(localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.items));
    if (result === null) {
      this.items = [];
    } else {
      this.items = result;
    }
  }

  getFavourites() {
    // this.dataLoaded = false;
    // this.dataLoading = true;
    const accessToken = localStorage.getItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
    if (accessToken) {
      this.apiHandler.callFavoutitesList({}, this._configs.apiKeys.profile).subscribe((data: any) => {
        this.favourite = data.list;
        // this._configs.addFavouriteInArray(data.list);
        localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(this.favourite));
        // this.dataLoaded = true;
        // this.dataLoading = false;
      }, (err) => {
        // this.dataLoaded = true;
        // this.dataLoading = false;
        this._configs.serverMessageHandler(err);
      });
    }
  }

  removeToFavourites(data) {
    data.isLike = true;
    this.apiHandler.addToFavourites({}, this._configs.apiKeys.profile, data.item_id).subscribe(() => {
      this.getFavourites();
      // this._configs.addFavouriteInArray(data.list);
      // localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.favouriteItemArray, JSON.stringify(this.objList));
      this.deleteFavouriteDisabled = false;

      this._configs.success(this.translate.instant('LOAD.SUCCESS.REMOVED_SUCCESSFULLY'));
    }, (err) => {
      this.deleteFavouriteDisabled = true;
      this._configs.serverMessageHandler(err);
    });
  }

  cartOpenForceFully(value) {
    this.isCartPopupShow = value;
    if (this.isCartPopupShow) {
      // console.log('called');
      // window.scroll(0, 0);
      this._configs.smoothScroll();
      // console.log(window.screen.height);
    }
    this.setAndRemoveAttribute(this.isCartPopupShow);
  }

  favouriteOpenForceFully(value) {
    this.isFavouritePopupShow = value;
    if (this.isFavouritePopupShow) {
      // console.log('called');
      // window.scroll(0, 0);
      this._configs.smoothScroll();
      // console.log(window.screen.height);
    }
    this.setAndRemoveAttribute(this.isFavouritePopupShow);
  }

  // async sendOtp() {
  //   this.formSubmitAttempt = false;
  //   if (this.signupForm.get('username').valid) {
  //     const name = this.signupForm.get('username').value;
  //     await this._auth.sendOtp({ username: name }, this._configs.apiKeys.send_otp).subscribe((data: any) => {
  //       this._configs.success(this.translate.instant('LOAD.SUCCESS.OTP_SUCCESS'));
  //       this.otpOptionButton = false;
  //       this.otpOptionShow = true;
  //     }, (err) => {
  //       this.otpOptionButton = true;
  //       this.otpOptionShow = false;
  //       // this._configs.dropDownShowHide(false);
  //       this._configs.serverMessageHandler(err);
  //     });
  //   } else {
  //     this.formSubmitAttempt = true;
  //   }
  // }

  // forceFullyChangeHaeder() {
  //   this.isFlag = false;
  //   // this.router.navigate([this._configs.routeUrl.index]);
  // }

  titleSetSubCategory(data) {
    let title;
    if (data) {
      this.subCategories.forEach(element => {
        if (element.slug === data) {
          title = element.meta_title;
        }
      });
    }
    return title || null;
  }

  clearErrorMessage() {
    // console.log('called');
    this.errorMessage = '';
    this.mobileValidationMessage = '';
    // this.formSubmitting = true;
  }

  openRazorPay() {

    this.razorPayOptions = this._configs.rezorPayOptions;
    this.razorPayOptions.handler = (data) => {
      this.ngZone.run(() => {
        this.paymentCapture(data);
      });
    }
    // this.razorPayOptions = {
    //   "key": "rzp_test_k65NGyP73xDOu3",
    //   "amount": "100", // 2000 paise = INR 20
    //   "currency": "USD",
    //   "name": "Arham Vijja",
    //   "description": "subscription process",
    //   "image": "assets/img/logo-dark.png",
    //   "handler": this.paymentCapture.bind(this),
    //   "prefill": {
    //     "name": "",
    //     "email": ""
    //   },
    //   "notes": {
    //     "address": "type address here"
    //   },
    //   "theme": {
    //     "color": "#F37254"
    //   }
    // };

    let rzp1 = new Razorpay(this.razorPayOptions);
    rzp1.open();

  }

  async paymentCapture(response) {
    // console.log(response);
    let flag = true;
    await this.apiHandler.callPaymentFlagQuery('payment', {
      payment_data: {
        payment_reference_number: response.razorpay_payment_id,
        payment_amount: 1,
        payment_currency: "usd"
      }
    }, this.token).subscribe((data: any) => {
      flag = false;
      this.signinCommon(this.signinData);
    }, (err) => {
      flag = true;
      this._configs.serverMessageHandler(err);
    });

    if (flag) {
      localStorage.removeItem(this._configs.lsPrefix + this._configs.localStorage.accessToken);
      localStorage.removeItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction);
    }

  }

  signinCommon(data) {

    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.accessToken, data.token);
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction,
      JSON.stringify(this._configs.idleTime));

    // current user details
    if (data.item) {
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.currentUser,
        JSON.stringify({
          first_name: data.item.first_name,
          email: data.item.email,
          last_name: data.item.last_name,
          phone: data.item.phone
          // isNewAccount: data.item.isNewAccount
          // type: data.item.type
        })
      );
    }

    // Setup current user role/permission data
    if (data.item.role) {
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.permissions,
        JSON.stringify(data.item.role.permissions));
    }
    this._configs.success(this.translate.instant('LOAD.SUCCESS.LOGIN_SUCCESS'));

    // this.checkFavouriteList();

    if (data.order && data.order !== null) {
      this.checkCartItemAlreadyExists(data.order);
    }

    // this.getGuestItem();
    // if (this.checkOut === true) {
    //   this.router.navigate([this._configs.routeUrl.my_cart]);
    //   this._configs.dropDownShowHide(false);
    //   // this.flag = true;
    //   // this.getAccessToken.emit(this.flag);
    // }

    // if (this.checkOut === false) {
    this.router.navigate([this._configs.routeUrl.dashboard]);
    // }
  }

  isPyamentDoneOrNot(data) {

    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.accessToken, data.token);
    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.lastInteraction,
      JSON.stringify(this._configs.idleTime));

    if (data.payment_done) {
      this.signinCommon(data);
    } else {
      this.signinData = data;
      this._configs.success(this.translate.instant('LOAD.MESSAGE.SUBCRIBE_MIDASTOUCH'));
      setTimeout(() => {
        this.openRazorPay();
      }, 3000);
    }
  }

  favoutiteItem(favouriteItem) {
    this.apiHandler.addToFavouriteBulk({ favouriteObj: favouriteItem }, this._configs.apiKeys.profile).subscribe((data: any) => {
      this._configs.addFavouriteInArray([]);
    }, (err) => {
      this._configs.serverMessageHandler(err);
    });
  }

  checkCartItemAlreadyExists(data) {
    this.checkLocalStorage();
    if (data) {
      data.time = {
        hours: data.shoot_hour,
        minutes: data.shoot_minute,
        type: data.shoot_meridiem
      };

      // if (data.time.hours === 0) {
      //   data.time.hours = '00';
      // }

      // if (data.time.hours < 10) {
      //   data.time.hours = '0' + data.time.hours;
      // }

      // if (data.time.minutes < 10) {
      //   data.time.minutes = '0' + data.time.minutes;
      // }

      if (this.items.length === 0 && data.items.length > 0) {
        // console.log('1');
        const cartItemsArray = [];
        if (data.items && data.items !== null) {

          data.items.forEach((element) => {
            const obj: any = {
              what_need_to_be_shoot: element.what_need_to_be_shoot,
              no_of_images: element.no_of_images,
              price: element.item.price,
              name: element.item.name,
              // help_text_how_much: element.item.help_text_how_much,
              // help_text_package: element.item.help_text_package,
              description: element.item.description,
              default_img: element.item.default_img,
              video_multiplier: element.item.video_multiplier,
              id: element.item.id,
              sub_category_id: element.item.sub_category_id,
              is_package: element.item.is_package,
              // max_photos_per_unit: element.item.max_photos_per_unit,
              // min_photos_per_unit: element.item.min_photos_per_unit,
              carousel_wallpaper: element.item.carousel_wallpaper,
              images: element.item.images,
              // min_units: element.item.min_units,
              media_type: element.item.media_type,

              category: {
                how_much_type: element.item.category.how_much_type
              },

              sub_category: {
                how_much_text: element.item.sub_category.how_much_text
              },

              //midas
              selected_time: element.selected_time,
              selected_date: element.selected_date,
              selected_mentor: element.selected_mentor
            };

            obj.how_much_quantity = element.how_much_quantity;

            // if (element.item.category.how_much_type === 'text') {
            //   obj.how_much_quantity = element.how_much_quantity;
            // }

            // if (element.item.category.how_much_type === 'time-range') {
            //   // obj.how_much_from = element.how_much_from;
            //   // obj.how_much_to = element.how_much_to;
            //   obj.no_of_hours = element.item.no_of_hours;
            // }

            cartItemsArray.push(obj);

          });

          // console.log(data.items);
          data.items = cartItemsArray;
          // this.items = data.items;
        }
      }

      if (this.items.length > 0 && data.items.length > 0) {
        // console.log('2');
        data.items = this.items;
        const queryCall = this.apiHandler.updateOrders(data, this._configs.apiKeys.orders, data.id);
        // let successMsg = this.translate.instant('LOAD.SUCCESS.ORDER_UPDATE');
        queryCall.subscribe((res: any) => {
          // console.log()
          data.id = res.item.id;
          localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(data));
          localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(data.items));
          this._configs.changeCartLength();
        }, (err) => {
          this._configs.serverMessageHandler(err);
        });
      }

      if (this.items.length > 0 && data.items.length === 0) {
        // console.log('3');
        data.items = this.items;
      }

      if (this.items.length === 0 && data.items.length === 0) {
        data.items = [];
      }

      // const items = cartItemsArray;
      this._configs.addedItem(data.items);
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.items, JSON.stringify(data.items));
      this._configs.changeCartLength();
      // delete data['items'];
      localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.cartItems, JSON.stringify(data));
      this._configs.changeCartLength();
      // console.log(data);
    }
  }


}
