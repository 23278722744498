import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AppConfigsService } from '../services/app-configs/app-configs.service';
import { ApiHandlerService } from '../services/api-handler/api-handler.service';
import { SigninComponent } from '../../layouts/auth/signin/signin.component';
// import { SignupComponent } from '../../layouts/auth/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '../directive/shared-directives.module';
import { SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
// import { MyCartComponent } from './my-cart/my-cart.component';
import { ModalFeedbackComponent } from './modal-feedback/modal-feedback.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RatingModule } from 'ng-starrating';
import { AddCartRestrictComponent } from './add-cart-restrict/add-cart-restrict.component';
import { ModalRescheduleCancelComponent } from './modal-reschedule-cancel/modal-reschedule-cancel.component';
import { ModalSavedLocationComponent } from './modal-saved-location/modal-saved-location.component';
import { ModalCartDeleteComponent } from './modal-cart-delete/modal-cart-delete.component';
import { CarouselItemsComponent } from './carousel-items/carousel-items.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ECommerceCarouselComponent } from './e-commerce-carousel/e-commerce-carousel.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ModalSelectActionComponent } from './modal-select-action/modal-select-action.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FAQsListComponent } from './faqs-list/faqs-list.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalSubmitcartComponent } from './modal-submitcart/modal-submitcart.component';
import { ModalSubmitErrorComponent } from './modal-submit-error/modal-submit-error.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ModalTimeDisplayComponent } from './modal-time-display/modal-time-display.component';
import { ModalRescheduleMessageComponent } from './modal-reschedule-message/modal-reschedule-message.component';
import { ModalVerificationComponentComponent } from './modal-verification-component/modal-verification-component.component';
import { ModalAccountInactiveComponent } from './modal-account-inactive/modal-account-inactive.component';
import { ModalConfirmFavouriteComponent } from './modal-confirm-favourite/modal-confirm-favourite.component';
import { ModalOrderSummary } from './modal-order-summary/modal-order-summary.component';
import { ModalCookieComponent } from './modal-cookie/modal-cookie.component';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { CartSubmitSlotComponent } from './cart-submit-slot/cart-submit-slot/cart-submit-slot.component';
import { CustomPaginatiomComponent } from './custom-pagination/custom-paginatiom/custom-pagination.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';

// import { CalendarModule, DateAdapter, MOMENT, CalendarMomentDateFormatter, CalendarDateFormatter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// import * as moment from 'moment';

// import { ClickOutsideModule } from 'ng-click-outside';
// import { CategoriesComponent } from 'src/app/layouts/index/categories/categories.component';



const components = [
  HeaderComponent,
  FooterComponent,
  SidenavComponent,
  // CategoriesComponent,
  // SigninComponent,
  // SignupComponent,
  // MyCartComponent,
  ModalFeedbackComponent,
  AddCartRestrictComponent,
  ModalRescheduleCancelComponent,
  ModalSavedLocationComponent,
  ModalCartDeleteComponent,
  CarouselItemsComponent,
  ECommerceCarouselComponent,
  ModalSelectActionComponent,
  FAQsListComponent,
  ModalSubmitcartComponent,
  ModalSubmitErrorComponent,
  ModalTimeDisplayComponent,
  ModalRescheduleMessageComponent,
  ModalVerificationComponentComponent,
  ModalAccountInactiveComponent,
  ModalConfirmFavouriteComponent,
  ModalOrderSummary,
  CartSubmitSlotComponent,
  ModalCookieComponent,
  CustomPaginatiomComponent
];

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
  keepSelectedItems: false
};
// export function momentAdapterFactory() {
// 	return adapterFactory(moment);
// }

@NgModule({
  declarations: [components],
  imports: [
    CarouselModule,
    CommonModule,
    AccordionModule.forRoot(),
    TranslateModule.forChild(),
    RouterModule,
    FormsModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    SharedDirectivesModule,
    NgbModule,
    RatingModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LazyLoadImageModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    // CalendarModule.forRoot({
    // 	provide: DateAdapter,
    // 	useFactory: adapterFactory,
    // },
    // 	{
    // 		dateFormatter: {
    // 			provide: CalendarDateFormatter,
    // 			useClass: CalendarMomentDateFormatter,
    // 		}
    // 	}
    // ),
    // ClickOutsideModule
  ],
  exports: [components],
  entryComponents: [
    ModalCartDeleteComponent,
    ModalRescheduleMessageComponent,
    ModalAccountInactiveComponent,
    ModalConfirmFavouriteComponent,
    CartSubmitSlotComponent,
    ModalOrderSummary
  ],
  providers: [AppConfigsService, ApiHandlerService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('951690941426-mg2b3j7oadebm5ng3ee7td7n4s9jdpla.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('848132419055450'),
          },
          // {
          //   id: AmazonLoginProvider.PROVIDER_ID,
          //   provider: new AmazonLoginProvider(
          //     'clientId'
          //   ),
          // },
        ],
      } as SocialAuthServiceConfig,
    }
  ]
})
export class SharedComponentModule { }
