import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-submit-error',
  templateUrl: './modal-submit-error.component.html',
  styleUrls: ['./modal-submit-error.component.scss']
})
export class ModalSubmitErrorComponent implements OnInit {
  onClose: any;
  confirmMessage: any;
  constructor(
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    // this.confirmMessage 
  }

}
