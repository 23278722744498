import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SharedComponentModule } from './shared/components/shared-component.module';
import { AppConfigsService } from './shared/services/app-configs/app-configs.service';
import { ApiHandlerService } from './shared/services/api-handler/api-handler.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard } from './shared/guards/auth.guard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedDirectivesModule } from './shared/directive/shared-directives.module';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider, AmazonLoginProvider } from 'angularx-social-login';
import { AppInterceptorService } from './shared/services/app-interceptor/app-interceptor.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RatingModule } from 'ng-starrating';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CommonModule } from '@angular/common';
import { ModalCookieComponent } from './shared/components/modal-cookie/modal-cookie.component';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { CartServiceService } from './shared/services/cart-service/cart-service.service';
// import { ClickOutsideModule } from 'ng-click-outside';

declare global {
  let fbId: any;
  let googleId: any;
  let phoneNumber: any;
  let emailId: any;
  let servingArea: any;
}

const routes: Routes = [
  // {
  //   path: 'index',
  //   loadChildren: () => import('./layouts/index/index.module').then(m => m.IndexModule),
  //   data: { preload: true }
  // },
  {
    path: '',
    loadChildren: () => import('./layouts/auth/auth.module').then(m => m.AuthModule),
    data: { preload: true }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./layouts/main/main.module').then(m => m.MainModule),
    data: { preload: true }
  },
  {
    path: 'not-found',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/not-found/not-found.module').then(m => m.NotFoundModule),
    data: { preload: true }
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
  keepSelectedItems: false
};

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true
    }),
    CommonModule,
    SharedComponentModule,
    HttpClientModule,
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    SharedDirectivesModule,
    FormsModule,
    RatingModule,
    NgbModule,
    ModalModule.forRoot(),
    CarouselModule,
    ToastrModule.forRoot(
      {
        progressBar: true,
        timeOut: 3000,
        maxOpened: 5,
        preventDuplicates: true
      }
    ),
    BrowserAnimationsModule, // ToastrModule added
    AccordionModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SocialLoginModule,
    LightboxModule,
    LazyLoadImageModule,
    NgxSelectModule,
    NgxSelectModule.forRoot(CustomSelectOptions)
    // ClickOutsideModule
  ],
  exports: [
    RouterModule
  ],
  providers: [AppConfigsService, ApiHandlerService, CookieService, CartServiceService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // googleId
            provider: new GoogleLoginProvider(googleId),
            // '951690941426-trcgl8g76las4r4nhepi80devs4mevsk.apps.googleusercontent.com'
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            // fbId
            provider: new FacebookLoginProvider(fbId),
          }
        ],
      } as SocialAuthServiceConfig,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true
    },
    Title
  ],
  entryComponents: [ModalCookieComponent]
})

export class AppRoutingModule {

  constructor(
    public translate: TranslateService,
    public _configs: AppConfigsService
  ) {
    // const IDS: any = Object.entries(_configs.nativeWindow).map(([key, value]) => ({ key, value }));
    fbId = _configs.nativeWindow.fbId;
    googleId = _configs.nativeWindow.googleId;

    const appConfig: any = {
      emailId: _configs.nativeWindow.emailId,
      phoneNumber: _configs.nativeWindow.phoneNumber,
      servingArea: _configs.nativeWindow.servingArea,
      googleMapKey: _configs.nativeWindow.googleMapAPIKey
    };

    localStorage.setItem(this._configs.lsPrefix + this._configs.localStorage.appConfigs, JSON.stringify(appConfig));

    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    const siteLang = localStorage.getItem(this._configs.lsPrefix + 'site-lang');
    if (siteLang) {
      translate.use(siteLang);
    } else {
      translate.use('en');
    }

  }
}
