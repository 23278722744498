import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-modal-account-inactive',
  templateUrl: './modal-account-inactive.component.html',
  styleUrls: ['./modal-account-inactive.component.scss']
})
export class ModalAccountInactiveComponent implements OnInit {
  onClose: any;
  step = 1;
  userName;
  constructor(
    public translate: TranslateService,
    public bsModalRef: BsModalRef,
    public _configs: AppConfigsService,
    public auth: AuthService) { }

  ngOnInit() {}

  userNameMail() {
    this.auth.resedLink({
      username: this.userName
    }).subscribe(() => {
      this.step = 2;
    }, () => {
      this.step = 2;
    });
  }
}

