import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppConfigsService } from '../../services/app-configs/app-configs.service';

@Component({
  selector: 'app-modal-verification-component',
  templateUrl: './modal-verification-component.component.html',
  styleUrls: ['./modal-verification-component.component.scss']
})
export class ModalVerificationComponentComponent implements OnInit {
  onClose: any;
  constructor(
    public translate: TranslateService,
    public bsModalRef: BsModalRef,
    public _configs: AppConfigsService) { }

  ngOnInit() {
  }
}
